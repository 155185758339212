module.exports = {
  QBconfig: {
    credentials: {
      appId: process.env.REACT_APP_QB_APPID,
      authKey: process.env.REACT_APP_QB_AUTH_KEY,
      authSecret: process.env.REACT_APP_QB_AUTH_SECRET,
      accountKey: process.env.REACT_APP_QB_ACCOUNT_KEY,
    },
    appConfig: {
      chatProtocol: {
        active: 2,
      },
      streamManagement: {
        enable: true,
      },
      debug: {
        mode: 0,
        file: null,
      },
    },
  },

  CONSTANTS: {
    DIALOG_TYPES: {
      CHAT: 3,
      GROUPCHAT: 2,
      PUBLICCHAT: 1,
    },
    ATTACHMENT: {
      TYPE: "image",
      BODY: "[attachment]",
      MAXSIZE: 5 * 1000000, // set 2 megabytes,
      MAXSIZEMESSAGE: "Image is too large. Max size is 2 mb.",
    },
    NOTIFICATION_TYPES: {
      NEW_DIALOG: "1",
      UPDATE_DIALOG: "2",
      LEAVE_DIALOG: "3",
    },
    CHAT_TYPE: {
      CHAT: "Chat",
    },
  },
};
