import moment from "moment";
import { pickDropInitData } from "./Functions";

// All Regex Validations
export const INPUT_VALIDATOR = {
  noSpeacialCharRegex: /^[a-zA-Z0-9_.-]*$/,
  emailRegex:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  inputText: /^[a-zA-Z\u0600-\u06FF,-][\sa-zA-Z\u0600-\u06FF,-]*$/,
  passwordRegex:
    /^(?=.*[0-9])(?=.*[- ?!@#$%^&*\/\\])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9- ?!@#$%^&*\/\\]{8,}$/,
  numberRegx: /^[0-9]{10}$/,
  alphaOnlyRegx: /^[aA-zZ\s]+$/,
  phoneRegExp: /^[+0-9]{7,}$/,
  updatePhoneRegExp: /^[+0-9]$/,
  onlyNumberRegx: /^[0-9]*$/,
  alphaNumericRegx: /^([A-Za-z0-9]+)$/,
  decimalNumberRegx: /^([0-9]+\.[0-9]+)|^[0-9]+$/,
  facebookUrlRegex:
    /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/gi,
  instagramUrlRegex:
    /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/gim,
  httpsRegex:
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
};

// Roles permission object
export const permissionObj = {
  read: false,
  edit: false,
  create: false,
  delete: false,
};

export const checkBoxObj = {
  checkbox1: [
    { id: 1, perm: "read" },
    { id: 2, perm: "edit" },
    { id: 3, perm: "create" },
    { id: 4, perm: "delete" },
  ],
  checkbox2: [
    { id: 5, perm: "read" },
    { id: 6, perm: "edit" },
    { id: 7, perm: "create" },
    { id: 8, perm: "delete" },
  ],
  checkbox3: [
    { id: 9, perm: "read" },
    { id: 10, perm: "edit" },
    { id: 11, perm: "create" },
    { id: 12, perm: "delete" },
  ],
  checkbox4: [
    { id: 13, perm: "read" },
    { id: 14, perm: "edit" },
    { id: 15, perm: "create" },
    { id: 16, perm: "delete" },
  ],
  checkbox5: [
    { id: 17, perm: "read" },
    { id: 18, perm: "edit" },
    { id: 19, perm: "create" },
    { id: 20, perm: "delete" },
  ],
  checkbox6: [
    { id: 21, perm: "read" },
    { id: 22, perm: "edit" },
    { id: 23, perm: "create" },
    { id: 24, perm: "delete" },
  ],
  checkbox7: [
    { id: 25, perm: "read" },
    { id: 26, perm: "edit" },
    { id: 27, perm: "create" },
    { id: 28, perm: "delete" },
  ],
  checkbox8: [
    { id: 29, perm: "read" },
    { id: 30, perm: "edit" },
    { id: 31, perm: "create" },
    { id: 32, perm: "delete" },
  ],
  checkbox9: [
    { id: 33, perm: "read" },
    { id: 34, perm: "edit" },
    { id: 35, perm: "create" },
    { id: 36, perm: "delete" },
  ],
  checkbox10: [
    { id: 37, perm: "read" },
    { id: 38, perm: "edit" },
    { id: 39, perm: "create" },
    { id: 40, perm: "delete" },
  ],
  checkbox11: [
    { id: 41, perm: "read" },
    { id: 42, perm: "edit" },
    { id: 43, perm: "create" },
    { id: 44, perm: "delete" },
  ],
  checkbox12: [
    { id: 45, perm: "read" },
    { id: 46, perm: "edit" },
    { id: 47, perm: "create" },
    { id: 48, perm: "delete" },
  ],
  checkbox13: [
    { id: 49, perm: "read" },
    { id: 50, perm: "edit" },
    { id: 51, perm: "create" },
    { id: 52, perm: "delete" },
  ],
};

export const yearArray = [
  { i: 1, month: "Jan" },
  { i: 2, month: "Feb" },
  { i: 3, month: "Mar" },
  { i: 4, month: "Apr" },
  { i: 5, month: "May" },
  { i: 6, month: "Jun" },
  { i: 7, month: "Jul" },
  { i: 8, month: "Aug" },
  { i: 9, month: "Sep" },
  { i: 10, month: "Oct" },
  { i: 11, month: "Nov" },
  { i: 12, month: "Dec" },
];
export const currentYear = moment().year();
export const currentMonth = moment().month() + 1;

export const rolePermissions = {
  DASHBOARD: "DashBoard",
  MASTER: "Master",
  MANAGETEAM: "ManageTeam",
  LOADBOARD: "LoadBoard",
  SHIPPPERS: "Shipppers",
  CARRIERS: "Carriers",
  LOADTRACKING: "LoadTracking",
  CMS: "CMS",
  REPORTS: "Reports",
  LOADESTIMATES: "LoadEstimates",
  CONTACTUS: "ContactUs",
  NEWSLETTER: "NewsLetter",
  SETTINGS: "Settings",
};

export const roleLabels = {
  DASHBOARD: "Dashboard",
  MASTER: "Masters",
  MANAGETEAM: "Manage Team",
  LOADBOARD: "Load Board",
  SHIPPPERS: "Shippers",
  CARRIERS: "Carriers",
  LOADTRACKING: "Load Tracking",
  CMS: "CMS",
  REPORTS: "Reports",
  LOADESTIMATES: "Load Estimates",
  CONTACTUS: "Contact Us",
  NEWSLETTER: "News Letter",
  SETTINGS: "Settings",
};

export const creditStatus = {
  SELECT_OPTION: { val: "", label: "Select Status" },
  APPROVE: { val: "Approved", label: "Approved" },
  REJECT: { val: "Rejected", label: "Rejected" },
  PAID: { val: "Paid", label: "Paid" },
  CHANGE_CREDIT: { val: "Change Credit", label: "Change Credit" },
};

export const CONST_BACK_SLASH = "/";
export const accessToken = "accessToken";
export const DELETE_WARNING_MESSAGE = "Are you sure,You Want To Delete ?";
export const SELECT_FILE = "Select file";

export const ChangePwdInitialValues = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

export const siteInfoFileType = {
  SITE_FAVICON: "sitefavicon",
  APP_ICON: "appicon",
  SITE_LOGO: "sitelogo",
};

export const tabLinks = "links";

export const socilaMediaURL = {
  FACEBOOK: "https://www.facebook.com",
  INSTAGRAM: "https://www.instagram.com",
  TWITTER: "https://twitter.com",
  YOUTUBE: "https://www.youtube.com",
  LINKEDIN: "https://www.linkedin.com",
  TIKTOK: "https://www.tiktok.com",
};

export const userAdmin = "Admin";
export const TOGGLE = "toggle";

export const toggleName = {
  MANAGE_TEAM: "manageTeamToggle",
  MANAGE_CMS: "manageCMSToggle",
  SETTING: "settingsToggle",
  ACCOUNTING: "AccountingToggle",
};

export const columNames = {
  auctionList: [
    "Load#",
    "Type",
    "Shipper",
    "Pickup Location",
    "Pickup DT/TM",
    "Pickup Ref#",
    "Drop Off Location",
    "Drop Off DT/TM",
    "Drop Ref#",
    "Expires In",
    "Rate($)",
    "Bids",
    "Status",
    "Detention($)",
    "Action",
  ],
  manualList: [
    "Load#",
    "Type",
    "Shipper",
    "Pickup Location",
    "Pickup DT/TM",
    "Pickup Ref#",
    "Drop Off Location",
    "Drop Off DT/TM",
    "Drop Ref#",
    "Rate($)",
    "Bids",
    "Status",
  ],

  archiveList: [
    "Load#",
    "Type",
    "Shipper",
    "Pickup Location",
    "Pickup DT/TM",
    "Pickup Ref#",
    "Drop off Location",
    "Drop off DT/TM",
    "Drop Ref#",
    "Rate($)",
    "Action",
  ],

  bidRecived: [
    "Carrier",
    "Equipment Category",
    "Truck#",
    //"Age on FFL (Days)",
    "Bid($)",
    "Status",
    "Action",
  ],
  potentialCarrier: [
    "Carrier",
    "Equipment Category",
    //"Age on FFL (Days)",
    "Action",
  ],

  loadEstimate: [
    "Type",
    "Equipment Type",
    "Pickup",
    "Drop off",
    "Miles",
    "Weight(LBS)",
    "Estimated Load Price",
    "Estimated Bid Price",
    "Action",
  ],

  adminNotification: [
    "Notification Description",
    "Type",
    "Received Date",
    "Action",
  ],
  loadReports: [
    "Load#",
    "From Location",
    "To Location",
    "Weight(LBS)",
    "Shipper",
    "Carrier",
    "Posted Rate",
    "Final Rate",
    "Status",
  ],

  feedbackComplain: [
    "Customer Info",
    "User type",
    "Phone #",
    "Received Date",
    "Reply Date",
    "Reply Status",
    "Action",
  ],

  carrierList: ["Name", "Company Name", "Email", "Phone No.", "US Dot#", "MC#"],
  shipperList: [
    "Name",
    "Company Name",
    "Email",
    "Phone No.",
    "Total Credit($)",
    "Remaining Credit($)",
  ],
  shipperGraphList: [
    "Name",
    "Email",
    "Phone No.",
    "Total Credit($)",
    "Remaining Credit($)",
    "Account",
    // "Status",
    "Action",
  ],
  carrierGraphList: [
    "Name",
    "Email",
    "Phone No.",
    //"Status",
    "Action",
  ],
  loadGraphList: [
    "Load",
    "Type",
    "Shipper",
    "Pickup Location",
    "Pickup DT/TM",
    "Drop off Location",
    "Drop off DT/TM",
    "Status",
    "Action",
  ],
  carrierLoads: [
    "Load#",
    "Type",
    "Shipper",
    "Pickup Location",
    "Pickup DT/TM",
    "Drop off Location",
    "Drop off DT/TM",
    "Rate($)",
    "Preliminary Status",
    "Load Run Out",
    "Confirmation Status",
    "Action",
  ],
  shipperLoads: [
    "Load#",
    "Type",
    "Pickup Location",
    "Pickup DT/TM",
    "Drop off Location",
    "Drop off DT/TM",
    "Rate$",
    "Status",
    "Action",
  ],
  dashboardList: [
    "Load#",
    "Type",
    "Shipper",
    "Pickup Location",
    "Pickup DT/TM",
    "Drop off Location",
    "Drop off DT/TM",
    "Status",
    "Action",
  ],
  earningList: [
    "Load#",
    "Type",
    "Shipper",
    "Pickup Location",
    "Pickup DT/TM",
    "Drop off Location",
    "Drop off DT/TM",
    "Commission($)",
    "Action",
  ],
  addTransctions: [
    "Total Credit($)",
    "Status",
    "Credit Used($)",
    "Paid Credit($)",
    "Remaining Credit($)",
    "Status DT/TM",
    "Transaction By",
  ],
  tenderLists: ["Documet Name", "Document", "Action"],
  invoiceList: [
    "Id",
    "Notes",
    "Vendor Name",
    "Pay To",
    "Ref/Driver",
    "Inv Date",
    "Aging Date",
    "Due Date",
    "QP Amount",
    "Outstanding Amount",
    "Check",
    "Draft",
    "Edit",
  ],
  newsletterList: [
    "Select",
    "Subscriber Name",
    "Subscriber Email",
    "Subscriber Phone",
    "User Type",
    "Subscribe Date",
    "Last Sent Date",
    "Status",
    "Action",
  ],
};

export const load_status = {
  posted: "posted",
  draft: "draft",
  accepted: "accepted",
  cancelled: "cancel",
  unposted: "unposted",
  bidposted: "BID POSTED",
  shared: "shared",
  pickedUp: "pickedUp",
  assigned: "assigned",
  delivered: "delivered",
};

export const ACCEPT_LOAD_STATUS = ["assigned", "delivered"];
export const activity_status = {
  PICKUP: "Pickup",
  DELIVERED: "Confirmdelivery",
  REJECTED: "RejectedByShipper",
  ACCEPTED: "AcceptedByShipper",
};
export const document_description = {
  PICKUP_DESCRIPTION: "BOL Uploaded on",
  DELIVERED_DESCRIPTION: "POD Uploaded on",
  REJECTED_DESCRIPTION: "Rejected POD on",
  ACCEPTED_DESCRIPTION: "Accepted POD on",
};

export const notification_status = {
  SUCCESS: "success",
  DANGER: "danger",
};

export const FeedbackReplyConfirmation =
  "Your reply has been sent successfully to the contact user email address.";

export const fileType = {
  VIEW: "view",
  DOWNLOAD: "download",
};

export const loadEstimateInitialValues = {
  loadType: "",
  equipmentTypeId: "",
  pickUpLocation: "",
  dropOffLocation: "",
  miles: "",
  weight: "",
  estimatedLoadPrice: "",
  estimatedBidPrice: "",
};

export const bidReceivedAction = {
  ACCEPT_BID: "Accepted",
  REJECT_BID: "Rejected",
};

export const loadMiles = ["10-50", "100-200", "200-300", "300-400", "400-500"];
export const loadWeight = ["10-50", "100-200", "200-300", "300-400", "400-500"];
export const loadTypeOption = ["Full", "Partial"];
export const valid_files = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "application/pdf",
  "application/msword",
];

export const dateTimeFormat = "DD MMMM YYYY hh:mmA";

export const SearchableDropDown = {
  PICKUP: "pickup",
  DROP_OFF: "dropOff",
  SHIPPER: "shipper",
  CARRIER: "carrier",
  DRIVER: "driver",
};

export const VALIDATION_MESSAGE = {
  PAID_AMOUNT_REQUIRED: "Paid-Amount is required",
  DETENTION_REQUIRED: "Detention is required",
  SAVE_FILTER: "Filter Saved Successfully",
};

//Load post constants

export const LOAD_POST = {
  FROM: "from",
  TO: "to",
  DATE_TO: "dateTo",
  DATE_FROM: "dateFrom",
  TIME: "time",
  TIME_TO: "timeTo",
  LOCATION: "location",
  STATE_BUILDING_NAME: "buildingName",
  REF_TYPE: "refType",
  REF_NUMBER: "refNumber",
  BUILDING_NAME: "Building name",
  PICK_UP: "pickUp",
  DROP_OFF: "dropOff",
  PICK_UP_DETAIL: "Pick Up Details",
  DROP_OFF_DETAIL: "Drop Off Details",
  ENTER_ADDRESS: "Enter address",
  LOAD_DETAIL: "loadDetail",
  LOAD_TYPE: "loadType",
  LOAD_TYPE_VALUE_FULL: "full",
  LOAD_TYPE_VALUE_PARTIAL: "partial",
  EQUIPMENT_ID: "equipmentId",
  EQUIPMENT_TYPE: "equipmentType",
  FREIGHT_TYPE: "freightType",
  FREIGHT_TYPE_ID: "freightTypeId",
  EQUIPMENT_SIZE: "equipmentSize",
  TEMPERATURE: "temperature",
  PRE_COOL_TEMPERATURE: "preCoolTemperature",
  LOAD_ARRANGMENT: "loadArrangement",
  SELECT_CLASS: "selectClass",
  HAZMAT: "Hazmat",
  HAZARDOUS_VALUE: "hazmat",
  NEED_DRIVER: "Driver's assist",
  LUMPER: "Lumper",
  NEED_DRIVER_VALUE: "needDriverAssistance",
  LUMPER_VALUE: "lumper",
  STACKABLE: "Stackable",
  STACKABLE_VALUE: "stackable",
  REFRIGERATED: "Refrigerated",
  REFER: "Refer",
  REEFER: "Reefer",
  TYPE: "type",
  COMMODITY_TYPE: "commodityType",
  LOAD_VALUE: "loadValue",
  DIMENSIONS: "dimensions",
  HEIGHT: "height",
  WIDTH: "width",
  LENGTH: "length",
  DESCRIPTION: "description",
  WEIGHT: "weight",
  QUANTITY: "quantity",
  VIEW_FREIGHT: "View Commodity",
  EDIT_FREIGHT: "Edit Commodity",
  FREIGHT: "freight",
  IMAGES: "images",
  DATE_RANGE: "dateRange",
  MANUAL: "manual",
  AUTO: "auto",
  NOTES_TO_CARRIER: "notesToCarrier",
  OFFER_PRICE: "offerPrice",
  PICK_UP_LOCATION: "Pickup Location*",
  DROP_OFF_LOCATION: "Dropoff Location*",
  FLATEBED: "Flatbed",
  MISCELLANEOUS_NOTES: "miscellaneousNotes",
  FSC: "FSC",
  APPOINTMENT: "Appointment",
};

export const TempratureEnable = [
  LOAD_POST.REFRIGERATED,
  LOAD_POST.REFER,
  LOAD_POST.REEFER,
];
export const EquipmentSizeEnable = [LOAD_POST.FLATEBED];

export const monthFormatMoment = "DD MMMM YYYY, LT";

export const loadArrangementTypes = {
  //stackable: { label: "Stakable", val: "stackable" },
  hazardous: { label: "Hazardous", val: "hazardous" },
  needDriverAssistance: {
    label: "Need Driver’s assist",
    val: "needDriverAssistance",
  },
};

export const inputFields = {
  TEXT: "text",
  NUMBER: "number",
  EMAIL: "email",
  FILE: "file",
};

export const timeNowInFormat = moment().format("HH:mm");

export const loadPostInitialDataFormPost = {
  dropOff: pickDropInitData(LOAD_POST.DROP_OFF),
  pickUp: pickDropInitData(LOAD_POST.PICK_UP),
  loadDetail: {
    loadType: "full",
    equipmentId: "",
    equipmentType: "",
    equipmentSize: "",
    temperature: "",
    preCoolTemperature: null,
    //loadArrangement: LOAD_POST.STACKABLE.toLowerCase(),
  },
  freight: [],
  notesToCarrier: "",
  offerPrice: "",
};

export const FreightListTableHeader = [
  "Shipping Method",
  "Commodity Type",
  "Dimensions",
  "Weight",
  "Quantity",
  "Load Arrangement",
  "Description",
  "Action",
];

export const VALIDATIONS_ERROR_MESSAGES = {
  DRIVER_NAME_REQUIRED: "Driver Name is required.",
  EQUIPMENT_TYPE_REQUIRED: "Equipment type is required",
  TEMPERATURE_REQUIRED: "Temperature is required",
  DRIVER1_NAME_REQUIRED: "Driver Name 1 is required",
  DRIVER2_NAME_REQUIRED: "Driver Name 2 is required",
  PRICE_OFFER: "price can't be greater than shipper’s offer price",
  DELETE_WARNING: "Are you sure you want to delete?",
  DRIVER_STATUS: "Driver status is pending",
  ERROR: "error",
  FILE_NOT_FOUND: "File not found.!",
  IMAGE_ONLY: "Please upload JPG/JPEG/PNG files only",
  PAYTERM_DOC: "Please upload pdf/docx/xls/xlsx files only",
  IMAGE_NOT_MORE_THEN_5: "Uploaded file size can't be more than 5 MB",
  FILE_SIZE: "Uploaded document can't be more than 5 MB",
  DOCUMENT_ONLY: "Please upload PDF/DOC/DOCX files only",
  VALID_EMAIL: "Please enter valid email*",
  IMAGE_REQUIRED: "Image is required",
  TRUCK_REQUIRED: "Truck size is required",
  MAKE_REQUIRED: "Make is required",
  MODEL_REQUIRED: "Equipment model is required",
  PLATE_NUMBER_REQUIRED: "Plate number is required",
  TRUCK_NUMBER_REQUIRED: "Truck number is required",
  TRAILER_NUMBER_REQUIRED: "Trailer number is required",
  LICENCE_PLATE_REQUIRED: "Licence plate is required",
  FILE_NOT_SUPPORTED: "This type of file not supported",
  PICK_UP_LOCATION_REQUIRED: "PickUp Location is required",
  REF_TYPE_REQUIRED: "Ref type is required",
  OFFER_PRICE_REQUIRED: "Offer price is required",
  PICK_UP_REF_NUM_REQUIRED: "PickUp Reference number is required",
  DROP_OFF_LOCATION_REQUIRED: "DropOff Location is required",
  DROP_OFF_REF_NUM_REQUIRED: "DropOff Reference number is required",
  EQUIPMENT_SIZE_REQUIRED: "Equipment Size is required",
  FREIGHT_TYPE_REQUIRED: "Freight type is required",
  COMMODITY_TYPE_REQUIRED: "Commodity type is required",
  FREIGHT_ADD_SUCCESS: "Commodity is added successfully",
  HEIGHT_REQUIRED: "Height is required",
  WIDTH_REQUIRED: "Width is required",
  LENGTH_REQUIRED: "Length is required",
  LENGTH_GREATER_THEN_ZERO: "Must greater than 0",
  MUST_BE_NUMBER: "Must be a number",
  DESCRIPTION_REQUIRED: "Description is required",
  WEIGHT_REQUIRED: "Weight is required",
  QUANTITY_REQUIRED: "Quantity is required",
  IMAGE_DIMENSIONS_VALIDATION:
    "Height and Width must not exceed 4500px X 4000px",
  IMAGE_SIZE_VALIDATION: "File size is too big!",
  VALID_EXTENSION: "Please upload file extensions .jpeg/.jpg/.png/.tiff",
  FREIGHT_REMOVED_SUCCESS: "Commodity is deleted successfully",
  SOMETHING_WENT_WRONG: "Something Went Wrong!",
  SELECT_HAZMAT_CLASS: "Hazmat class is required",
  SAVE_SELECTION_NAME: "Selection name required*",
  FILE_DOWNLOADED: "Downloaded file successfully",
  VALID_URL_HTTPS: "Invalid url, please enter valid url",
  VALID_CARRIER_DOC: "Please upload jpg, jpeg, png, pdf, docx, xml, files only",
  DESCRIPTION_LENGTH: "Description can not be more than 200 character",
  MISCELLANEOUS_NOTES_LENGTH:
    "Miscellaneous notes can not be more than 200 character",
  CONFIRM_LOADJOURNEY: "Are you sure, you want to confirm this load journey?",
  IS_CONFIRM_LOADJOURNEY: "Please confirm pickup/dropoff load journey",
  LOAD_PRICE_MIN_MAX: "Load price must be below 1 lakh and not be zero(0)",
};

export const freightInitData = {
  id: "",
  [LOAD_POST.FREIGHT_TYPE]: "",
  [LOAD_POST.FREIGHT_TYPE_ID]: "",
  [LOAD_POST.IMAGES]: [],
  [LOAD_POST.COMMODITY_TYPE]: "",
  [LOAD_POST.DIMENSIONS]: {
    [LOAD_POST.COMMODITY_TYPE]: "",
    [LOAD_POST.HEIGHT]: "",
    [LOAD_POST.WIDTH]: "",
    [LOAD_POST.LENGTH]: "",
    [LOAD_POST.DESCRIPTION]: "",
    [LOAD_POST.WEIGHT]: "",
    [LOAD_POST.QUANTITY]: "",
  },
  [LOAD_POST.LOAD_ARRANGMENT]: LOAD_POST.HAZARDOUS_VALUE,
};

export const PAYMODE_LABEL = {
  PAID_AMOUNT: "paidAmount",
  CASH: "cash",
  ONLINE: "online",
  CHEQUE: "cheque",
};
export const payModeOptions = ["cash", "online", "cheque"];
export const optionsYesNo = ["yes", "no"];
export const invoiceStatusOptions = ["posted", "unPosted"];

export const REPORT_TYPE = {
  ACTIVITY: "activity",
  ACTUAL_TOTAL_BY_CARRIER: "actualTotalByCarrier",
  AGING: "aging",
  CUSTOMER_SALES: "customerSales",
  MONTHLY_SALES: "monthlySales",
  PROJECT_TOTAL_BY_CARRIER: "projectedTotalByCarrier",
  PROJECT_TOTAL_BY_REVENUE: "projectedTotalByRevenue",
  TOTAL_BY_CUSTOMER: "totalByCustomer",
  VOIDED_SHIPMENT: "voidedShipment",
  WIDE_DELIVERY: "wideDelivery",
  WIDE_PROFIT_AND_DELIVERY: "wideProfitAndDelivery",
  WIDE_PROFIT: "wideProfit",
  CASH_REQUIREMENT: "cashRequirement",
};

export const OWNER_OPERATOR = "OwnerOperator";

export const TYPE = {
  SHIPPER: "shipper",
  CARRIER: "carrier",
  EARNINGS: "earnings",
  LOADS: "loads",
  DATE: "date",
  RADIO: "radio",
  DAILY: "daily",
  WEEKLY: "weekly",
  POSTED: "posted",
  PENDING: "pending",
  DELIVERED: "delivered",
};

export const PLACEHOLDER = {
  PRO: "Pro#",
  SEARCH: "Search...",
  SELECT: "Select",
};

export const UNSUBSCRIBE = "unsubscribe";
export const typeOptions = ["expected", "hold", "decline", "paid", "approved"];
export const typeAdjustOptions = ["send direct", "riviera"];
export const INVOICELOADSTATUS = { DRAFT: "draft", CHECK: "check" };

export const TABTYPE = {
  ALL_INVOICE: "allInvoices",
  SELECTED: "selected",
  AGING_SELECT: "agingSelected",
  AGING: "approved",
  CASH_REQUIREMENT: "cashRequirement",
  EXPORT_CVV: "export_cvv",
  ALL_INVOICES: "Active Invoices",
  DECLINE: "decline",
  HOLD: "hold",
  EXPECTED: "expected",
  PAID: "paid",
  ADJUST: "adjust",
};
export const RECEIVABLE_TABTYPE = {
  ALL_INVOICE: "allInvoices",
};
export const PAYMODE = "payMode";

export const STATUS_CHANGE = "status";

export const UNDEFINE = "undefined";

export const FILE_UPLOAD_EXTENSION = [
  "image/png", // for .png image
  "image/jpeg", // for .jpeg image
  "image/jpg", // for .jpg image
  "application/pdf", // for .pdf file
  "application/msword", // for .doc file
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // for .docx file
  "application/vnd.ms-excel", // for .xls file
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // for .xlsx file
];
export const PAY_TERM_SECTION_FILE_UPLOAD = [
  "application/pdf", // for .pdf file
  "application/msword", // for .doc file
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // for .docx file
  "application/vnd.ms-excel", // for .xls file
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // for .xlsx file
];

export const GRAPH_MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const GRAPH_WEEKLY = [
  "week1",
  "week2",
  "week3",
  "week4",
  "week5",
  "week6",
];

export const YEAR = "year";
export const MONTH = "month";
export const WEEK = "week";
export const PREVIOUS = "prev";
export const NEXT = "next";

export const DATE_FORMAT = [
  {
    value: "MM/DD/YYYY",
    label: "MM/DD/YYYY",
  },
  {
    value: "DD/MM/YYYY",
    label: "DD/MM/YYYY",
  },
  {
    value: "YYYY/MM/DD",
    label: "YYYY/MM/DD",
  },
];

export const PICKUP = "pickup";
export const DROPOFF = "dropOff";

export const PredefineLoadStatus = [
  "EnRouted",
  "ReachedPickup",
  "PickedUp",
  "ReachedDropOff",
  "ConfirmedDelivery",
];

export const LOAD_STATUS_OPTIONS = [
  "posted",
  "unposted",
  "pickedUp",
  "assigned",
  "delivered",
  "cancel",
];

export const CHANGE_LOAD_STATUS_OPTIONS = [
  "posted",
  "unposted",
  "cancel",
  "delete",
];

export const USER_TYPE = ["shipper", "carrier", "driver", "anonymous"];
export const NEWSLETTER_STATUS = ["subscribe", "unsubscribe"];
export const RATING_CRITERIA = [
  "Number of Loads Completed",
  "On Time Delivery",
  "Safety Rating",
  "Customer Service",
  "Timely Updates/POD’s",
  "FFLO - QuickPay",
  "FFLO - Fuel Card Member",
];

export const RIVIERA = "riviera";

export const SUPPORTED_FORMATS = {
  IMAGES: ["image/jpg", "image/png", "image/jpeg"],
  VIDEOS: ["video/mp4", "video/webm", "video/flv", "video/wmv", "video/avi"],
};
