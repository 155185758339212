export const gr = {
  CustomLabels: {
    dashboard: "Instrumententafel",
    users: "Benutzer",
    content: "Inhalt",
    emailTemplateslabel: "Email Benachrichtigung",
    master: "Meister",
    roles: "Rollen",
    Transcation: "Transkation",
    media: "Medien",
    support: "Unterstützung",
    settings: "die Einstellungen",
    smtp: "SMTP",
    payment: "SDK-Details des Zahlungsgateways",
    blogs: "blogs",
    adminuser: "admin Benutzer",
    staticpages: "Páginas estáticas",
    faq: "FAQ",
    generalsettings: "Allgemeine Einstellungen",
    socilamedia: "Sozialen Medien",
    SocialSdk: "Social Media SDK Details",
    SocialLinks: "Social Media Links",
    paymentGateway: "SDK-Details des Zahlungsgateways",
    emailNotification: "E-Mail-Benachrichtigungseinstellungen",
  },
  MasterMangement: {
    master: "Meister",
    manageMaster: "Master verwalten",
    addCountry: "Land hinzufügen",
    countryName: "Ländername",
    countryCode: "Landesvorwahl",
    phoneCode: "Telefoncode",
    add: "hinzufügen",
    addCountryTimeZone: "Länder-Zeitzone hinzufügen",
    timeZone: "Zeitzone",
    addCurrency: "Länderwährung hinzufügen",
    currency: "Währung",
    status: "Status",
    action: "Aktion",
    noRecord: "Keine Aufzeichnungen gefunden!",
  },
  GeneralSettings: {
    general: "General Settings",
    settings: "Settings",
    siteName: "Site Name",
    siteFavicon: "Site Favicon",
    siteSmallLogo: "Site Logo (Small)",
    siteBigLarge: "Site Logo (Large)",
    siteMaintainance: "Site Under Maintainance",
    siteMaintainanceMessage:
      "Set the website status to offline. Display the site offline messages.",
    offlineMessage: "Offline message",
    metaData: "Meta Data",
    metaTitle: "Meta Title",
    maxCharacterTitle: "Maximum 70 characters is suitable",
    metaDescription: "Meta Description",
    maxCharacterDescription: "Maximum 160 characters is suitable",
    metaKeywords: "Meta Keywords",
    maxCharacterKeywords: "Maximum 150 characters is suitable",
    analyticalData: "Analytical Data",
    headerSnippet: "Header Snippet",
    footerSnippet: "Footer Snippet",
    timezone: "Timezone",
    dateformat: "Date Format",
    currency: "Currency",
    timeFormat: "Time Format",
    startDate: "Start Date",
    endDate: "End Date ",
  },
  EmailList: {
    emailTemplates: "Email Templates",
    addNewTemplate: "Add New Template",
    templateTitle: "Template Title",
    ID: "ID",
    subject: "Subject",
    createdBy: "Created By",
    dateTime: "Date Time",
    action: "Action",
    noRecord: "No Records Found!",
  },
  EmailTemplates: {
    emailTemplates: "Email Templates",
    addNewTemplate: "Add New Template",
    templateTitle: "Template Title",
    title: "Title",
    subject: "Subject",
    fromemailAddress: "From email Address",
    tags: "Tags",
  },
  ContentManagement: {
    CMS: {
      edit: "Seite bearbeiten",
      add: "Seite hinzufügen",
      content: "Inhalt",
      category: "Kategorie",
      title: "Titel",
      slug: "Schnecke",
      metaKeywords: "Meta-Keywords",
      metaDescription: "Meta Beschreibung",
      blogDescription: "Blog Beschreibung",
      image: "Bild",
      caption: "Bildbeschriftung",
      postDate: "Nach Datum",
      tag: "Etikett",
      status: "Status",
      author: "Autor",
    },
    CMSListing: {
      blog: "Blogs",
      addNewPage: "Neue Seite hinzufügen",
      articleName: "Artikelname",
      category: "Kategorie",
      created: "Erstellt",
      status: "Status",
      action: "Aktion",
      noRecord: "Keine Aufzeichnungen gefunden!",
      search: "Suche",
    },
    FAQ: {
      content: "Inhalt",
      faq: "FAQ",
      faqList: "FAQ-Liste",
      faqCategory: "FAQ Kategorie",
      addCategory: "Kategorie hinzufügen",
      addNewFAQ: "Neue FAQ hinzufügen",
      category: "Kategorie",
      question: "Frage",
      answer: "Antworten",
      action: "Aktion",
      search: "Suche",
    },
  },
  Usermanagement: {
    edituser: {
      labels: {
        userprofile: "Benutzerprofil",
        firsname: "Vorname",
        lastname: "Familienname, Nachname",
        email: "Email",
        company: "Unternehmen",
        status: "Status",
        phoneNumber: "Telefonnummer",
        birthdate: "Geburtsdatum",
        website: "Webseite",
        gender: "Geschlecht",
        address: "Adresse",
        country: "Country",
      },
      buttons: {
        save: "sparen",
        saving: "Sparen",
        delete: "Delete",
      },
    },
  },
};
