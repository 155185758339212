/******************* 
@Purpose : Used for environment configuration
@Parameter : {API_URL, IMG_URL, PORT}
@Author : INIC
******************/
module.exports = {
  API_URL: process.env.REACT_APP_API_URL,
  IMG_URL: process.env.REACT_APP_IMG_URL,
  PUBLIC_FILE_URL: process.env.REACT_APP_PUBLIC_FILE_URL,
  PORT: process.env.REACT_APP_PORT,
  BUILD_PATH: process.env.REACT_APP_BUILD_PATH,
  FRONT_URL: process.env.REACT_APP_FRONT_URL,
  GOOGLE_MAP_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  SOCKET_URL: process.env.REACT_APP_SOCKET_URL,
};
