import { Suspense } from "react"
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom"
import { PrivateRoute } from "./Routes/PrivateRoute"
import { renderRoutes } from "./Routes/Routes"
import "./scss/styles.scss";
import { paths } from "./Routes/constants";
import { Authorization } from "./utils/Functions";
import Loader from "./components/Loader/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QBconfig } from "./QB_config";

/******************* 
@Purpose : This page is default page for our project
@Parameter : {}
@Author : INIC
******************/

function App() {
  const isAuth = Authorization();
  const QB = window.QB;

  QB.init(
    QBconfig.credentials.appId,
    QBconfig.credentials.authKey,
    QBconfig.credentials.authSecret,
    QBconfig.credentials.accountKey,
    QBconfig.appConfig
  );
  return (
    <div className="App">
      <Suspense fallback={<Loader />}>
        <Router>
          <ToastContainer />
          <Switch>
            {!isAuth ? (
              <Route exact path="/">
                <Redirect to={paths.LOGIN} />
              </Route>
            ) : (
              <Route exact path="/">
                <Redirect to={paths.DASHBOARD} />
              </Route>
            )}

            {renderRoutes.map(([key, route]) => {
              return (
                <PrivateRoute
                  key={key}
                  {...route}
                  privateRoute={route.private}
                />
              );
            })}
          </Switch>
        </Router>
      </Suspense>
    </div>
  );
}

export default App
