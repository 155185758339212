import { lazy } from "react";
import { paths } from "./constants";

const {
  LOGIN,
  EDIT_PROFILE,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  DASHBOARD,
  VIEW_USER_DETAIL,
  VIEW_LOAD_DETAILS,
  SHIPPER_GRAPH,
  CARRIER_GRAPH,
  LOADS_GRAPH,
  EARNINGS,
  ROLESLIST,
  ADDROLE,
  EDITROLE,
  MANAGETEAMUSERS,
  ADDUSERS,
  EDITUSERS,
  DETAILSUSERS,
  SHIPPERS,
  SHIPPER_PROFILE,
  SHIPPER_LOADS,
  SHIPPER_LOAD_DETAILS,
  TENDERS,
  LOAD_POST,
  CARRIERS,
  CARRIER_PROFILE,
  CARRIER_LOADS,
  CARRIER_LOAD_DETAIL,
  CARRIER_EQUIPMENT,
  CARRIER_EQUIPMENT_DETAIL,
  CARRIER_DRIVER,
  CARRIER_DRIVER_DETAIL,
  NOTIFICATION,
  NEWSLETTER,
  NEWSLETTER_REPLAY,
  UNSUBSCRIBE_USER,
  MASTER,
  LOAD_BOARD,
  LOAD_TRACKING,
  TESTDATA,
  FAQ,
  FREIGHT_TYPE_LIST,
  DRIVER_STATUS_LIST,
  ADDEDITCMSPRIVACY,
  ADDEDITCMSTERMS,
  ADDEDITCMSDISCLAIMER,
  ADDEDITCMSHOME,
  ADDEDITCMSABOUT,
  FEEDBACK_COMPLAINS,
  FEEDBACK_COMPLAINS_DETAILS,
  PLAN_CONFIGURATION,
  LOAD_ESTIMATES,
  LOAD_REPORTS,
  LOAD_REPORTS_LOAD_DETAILS,
  SETTINGS_GENERAL,
  SETTINGS_EMAIL,
  SETTINGS_SOCIALMEDIA,
  SETTINGS_IMPORTED_LINKS,
  AUCTION_LOAD_DETAIL,
  MANUAL_LOAD_DETAIL,
  ARCHIVE_LOAD_DETAIL,
  CHAT,
  ACCOUNT_INVOICES,
  ACCOUNT_REPORTS,
  PAGE_NOTFOUND,
  CARRIER_RATING,
  DISPATCHER_DETAILS,
  ACCOUNT_RECEIVABLE,
  ACCOUNT_PAYABLE,
} = paths;

export const routes = {
  login: {
    path: LOGIN,
    component: lazy(() => import("../pages/Authentication/AdminLogin")),
    exact: true,
    private: false,
  },
  edit_profile: {
    path: EDIT_PROFILE,
    component: lazy(() => import("../pages/Profile/Profile")),
    exact: true,
    private: true,
  },
  forgotpassword: {
    path: FORGOT_PASSWORD,
    component: lazy(() => import("../pages/Authentication/ForgotPassword")),
    exact: true,
    private: false,
  },
  resetpassword: {
    path: RESET_PASSWORD,
    component: lazy(() => import("../pages/Authentication/ResetPassword")),
    exact: true,
    private: false,
  },
  dashboard: {
    path: DASHBOARD,
    component: lazy(() => import("../pages/Dashboard/Dashboard")),
    exact: true,
    private: true,
  },
  userdetail: {
    path: VIEW_USER_DETAIL,
    component: lazy(() => import("../pages/Dashboard/UserDetail")),
    exact: true,
    private: true,
  },
  viwload_details: {
    path: VIEW_LOAD_DETAILS,
    component: lazy(() => import("../pages/Dashboard/LoadDetails")),
    exact: true,
    private: true,
  },
  shipper_graph: {
    path: SHIPPER_GRAPH,
    component: lazy(() => import("../pages/Dashboard/ShipperGraph")),
    exact: true,
    private: true,
  },
  carrier_graph: {
    path: CARRIER_GRAPH,
    component: lazy(() => import("../pages/Dashboard/CarrierGraph")),
    exact: true,
    private: true,
  },
  loads_graph: {
    path: LOADS_GRAPH,
    component: lazy(() => import("../pages/Dashboard/LoadsGraph")),
    exact: true,
    private: true,
  },
  earnings: {
    path: EARNINGS,
    component: lazy(() => import("../pages/Dashboard/Earnings")),
    exact: true,
    private: true,
  },
  rolelist: {
    path: ROLESLIST,
    component: lazy(() => import("../pages/ManageTeam/RoleAccess/Roles")),
    exact: true,
    private: true,
  },
  addroles: {
    path: ADDROLE,
    component: lazy(() => import("../pages/ManageTeam/RoleAccess/AddRole")),
    exact: true,
    private: true,
  },
  editrole: {
    path: EDITROLE,
    component: lazy(() => import("../pages/ManageTeam/RoleAccess/EditRole")),
    exact: true,
    private: true,
  },
  manageteamusers: {
    path: MANAGETEAMUSERS,
    component: lazy(() => import("../pages/ManageTeam/Users/UsersList")),
    exact: true,
    private: true,
  },
  addusers: {
    path: ADDUSERS,
    component: lazy(() => import("../pages/ManageTeam/Users/AddUser")),
    exact: true,
    private: true,
  },
  editusers: {
    path: EDITUSERS,
    component: lazy(() => import("../pages/ManageTeam/Users/EditAdminUser")),
    exact: true,
    private: true,
  },
  detailsusers: {
    path: DETAILSUSERS,
    component: lazy(() => import("../pages/ManageTeam/Users/ViewuserDetails")),
    exact: true,
    private: true,
  },

  shippers: {
    path: SHIPPERS,
    component: lazy(() => import("../pages/Shippers/Shippers")),
    exact: true,
    private: true,
  },
  shipper_profile: {
    path: SHIPPER_PROFILE,
    component: lazy(() => import("../pages/Shippers/ShipperProfile")),
    exact: true,
    private: true,
  },
  shipper_load: {
    path: SHIPPER_LOADS,
    component: lazy(() => import("../pages/Shippers/ShipperLoads")),
    exact: true,
    private: true,
  },
  shipper_load_details: {
    path: SHIPPER_LOAD_DETAILS,
    component: lazy(() => import("../pages/Shippers/ShipperLoadDetails")),
    exact: true,
    private: true,
  },
  tenders: {
    path: TENDERS,
    component: lazy(() => import("../pages/Shippers/Tenders")),
    exact: true,
    private: true,
  },
  load_post: {
    path: LOAD_POST,
    component: lazy(() => import("../pages/Shippers/LoadPost")),
    exact: true,
    private: true,
  },
  carriers: {
    path: CARRIERS,
    component: lazy(() => import("../pages/Carriers/Carriers")),
    exact: true,
    private: true,
  },
  carrier_profile: {
    path: CARRIER_PROFILE,
    component: lazy(() => import("../pages/Carriers/CarrierProfile")),
    exact: true,
    private: true,
  },
  carrier_rating: {
    path: CARRIER_RATING,
    component: lazy(() => import("../pages/Carriers/CarrierRating.jsx")),
    exact: true,
    private: true,
  },
  carrier_load: {
    path: CARRIER_LOADS,
    component: lazy(() => import("../pages/Carriers/CarrierLoads")),
    exact: true,
    private: true,
  },
  carrier_load_details: {
    path: CARRIER_LOAD_DETAIL,
    component: lazy(() => import("../pages/Carriers/CarrierLoadDetails")),
    exact: true,
    private: true,
  },
  dispatcher_details: {
    path: DISPATCHER_DETAILS,
    component: lazy(() => import("../pages/Carriers/DispatcherDetails")),
    exact: true,
    private: true,
  },
  carrier_equipment: {
    path: CARRIER_EQUIPMENT,
    component: lazy(() => import("../pages/Carriers/CarrierEquipments")),
    exact: true,
    private: true,
  },
  carrier_equipment_details: {
    path: CARRIER_EQUIPMENT_DETAIL,
    component: lazy(() => import("../pages/Carriers/CarrierEquipmentsDetails")),
    exact: true,
    private: true,
  },
  carrier_driver: {
    path: CARRIER_DRIVER,
    component: lazy(() => import("../pages/Carriers/CarrierDrivers")),
    exact: true,
    private: true,
  },
  carrier_driver_details: {
    path: CARRIER_DRIVER_DETAIL,
    component: lazy(() => import("../pages/Carriers/CarrierDriverDetails")),
    exact: true,
    private: true,
  },
  notification: {
    path: NOTIFICATION,
    component: lazy(() => import("../pages/Notification/Notification")),
    exact: true,
    private: true,
  },
  newsletter: {
    path: NEWSLETTER,
    component: lazy(() => import("../pages/NewsLetter/NewsLetter")),
    exact: true,
    private: true,
  },
  newsletter_replay: {
    path: NEWSLETTER_REPLAY,
    component: lazy(() => import("../pages/NewsLetter/NewsLetterSend")),
    exact: true,
    private: true,
  },
  unsubscribe_user: {
    path: UNSUBSCRIBE_USER,
    component: lazy(() => import("../pages/NewsLetter/Unsubscribe_User")),
    exact: true,
    private: false,
  },
  master: {
    path: MASTER,
    component: lazy(() => import("../pages/Master/Master")),
    exact: true,
    private: true,
  },
  load_board: {
    path: LOAD_BOARD,
    component: lazy(() => import("../pages/LoadBoard/LoadBoard")),
    exact: true,
    private: true,
  },
  manual_load_detail: {
    path: MANUAL_LOAD_DETAIL,
    component: lazy(() => import("../pages/LoadBoard/ManualLoadDetails")),
    exact: true,
    private: true,
  },
  auction_load_detail: {
    path: AUCTION_LOAD_DETAIL,
    component: lazy(() => import("../pages/LoadBoard/AuctionLoadDetails")),
    exact: true,
    private: true,
  },
  archive_load_detail: {
    path: ARCHIVE_LOAD_DETAIL,
    component: lazy(() => import("../pages/LoadBoard/ArchiveLoadDetails")),
    exact: true,
    private: true,
  },
  load_tracking: {
    path: LOAD_TRACKING,
    component: lazy(() => import("../pages/LoadTracking/LoadTracking")),
    exact: true,
    private: true,
  },
  testdata: {
    path: TESTDATA,
    component: lazy(() => import("../pages/Contents/TestData")),
    exact: true,
    private: true,
  },
  faq: {
    path: FAQ,
    component: lazy(() => import("../pages/Contents/Faq")),
    exact: true,
    private: true,
  },
  freighttypelist: {
    path: FREIGHT_TYPE_LIST,
    component: lazy(() => import("../pages/Master/FreightType")),
    exact: true,
    private: true,
  },
  driverstatus: {
    path: DRIVER_STATUS_LIST,
    component: lazy(() => import("../pages/Master/DriverStatus")),
    exact: true,
    private: true,
  },
  addeditcmsprivacy: {
    path: ADDEDITCMSPRIVACY,
    component: lazy(() => import("../pages/Contents/AddEditCmsPrivacy")),
    exact: true,
    private: true,
  },
  addeditcmsterms: {
    path: ADDEDITCMSTERMS,
    component: lazy(() => import("../pages/Contents/AddEditCmsTerms")),
    exact: true,
    private: true,
  },
  addeditcmsdisclaimer: {
    path: ADDEDITCMSDISCLAIMER,
    component: lazy(() => import("../pages/Contents/AddEditCmsDisclaimer")),
    exact: true,
    private: true,
  },
  addeditcmshome: {
    path: ADDEDITCMSHOME,
    component: lazy(() => import("../pages/Contents/AddEditCmsHome")),
    exact: true,
    private: true,
  },
  addeditcmsabout: {
    path: ADDEDITCMSABOUT,
    component: lazy(() => import("../pages/Contents/AddEditCmsAboutUs")),
    exact: true,
    private: true,
  },
  feedback_complains: {
    path: FEEDBACK_COMPLAINS,
    component: lazy(() =>
      import("../pages/Feedback-Complains/Feedback-Complains")
    ),
    exact: true,
    private: true,
  },
  feedback_complains_details: {
    path: FEEDBACK_COMPLAINS_DETAILS,
    component: lazy(() =>
      import("../pages/Feedback-Complains/Feedback-Complains-Details")
    ),
    exact: true,
    private: true,
  },
  plan_configuration: {
    path: PLAN_CONFIGURATION,
    component: lazy(() =>
      import("../pages/PlanConfiguration/PlanConfiguration")
    ),
    exact: true,
    private: true,
  },
  load_estimates: {
    path: LOAD_ESTIMATES,
    component: lazy(() => import("../pages/LoadEstimates/LoadEstimates")),
    exact: true,
    private: true,
  },
  loadreports: {
    path: LOAD_REPORTS,
    component: lazy(() => import("../pages/LoadReport/LoadReports")),
    exact: true,
    private: true,
  },
  load_report_load_details: {
    path: LOAD_REPORTS_LOAD_DETAILS,
    component: lazy(() => import("../pages/LoadReport/LoadReportDetails")),
    exact: true,
    private: true,
  },
  settings_general: {
    path: SETTINGS_GENERAL,
    component: lazy(() => import("../pages/Settings/GeneralSettings")),
    exact: true,
    private: true,
  },
  settings_socialmedialinks: {
    path: SETTINGS_SOCIALMEDIA,
    component: lazy(() => import("../pages/Settings/SocialMedia")),
    exact: true,
    private: true,
  },
  settings_email: {
    path: SETTINGS_EMAIL,
    component: lazy(() => import("../pages/Settings/EmailSettings")),
    exact: true,
    private: true,
  },
  settings_imported_links: {
    path: SETTINGS_IMPORTED_LINKS,
    component: lazy(() => import("../pages/Settings/ImportedLinks")),
    exact: true,
    private: true,
  },
  chat: {
    path: CHAT,
    component: lazy(() => import("../pages/Chat/Chat")),
    exact: true,
    private: true,
  },
  account_invoices: {
    path: ACCOUNT_INVOICES,
    component: lazy(() => import("../pages/Accounting/AccountInvoices")),
    exact: true,
    private: true,
  },
  account_receivable: {
    path: ACCOUNT_RECEIVABLE,
    component: lazy(() => import("../pages/Accounting/AccountRecievable")),
    exact: true,
    private: true,
  },
  account_payable: {
    path: ACCOUNT_PAYABLE,
    component: lazy(() => import("../pages/Accounting/AccountRecievable")),
    exact: true,
    private: true,
  },
  account_reports: {
    path: ACCOUNT_REPORTS,
    component: lazy(() => import("../pages/Accounting/AccountReports")),
    exact: true,
    private: true,
  },
  pagenotfound: {
    path: PAGE_NOTFOUND,
    component: lazy(() => import("../pages/FourZeroFour/FourZeroFour")),
    exact: true,
    private: true,
  },
};

export const renderRoutes = Object.entries(routes);
