/******************* 
@Purpose :  To use these constants to show error messages 
@Author : INIC
******************/
export default {
  REQUIRED: "*Field Required",
  PROVIDE_EMAIL_OR_PHONE: "*Please enter email or phone Number",
  PROVIDE_EMAIL: "*Please enter email address",
  PROVIDE_VALID_EMAIL: "*Please enter valid email ",
  PROVIDE_PASSWORD: "*Please enter password",
  PROVIDE_CURRENT_PASSWORD: "*Please enter current password",
  PROVIDE_NEW_PASSWORD: "*Please enter new password",
  PROVIDE_VALID_PASSWORD: `* Password must be 8-12 Character (A-Z, a-z, 0-9, Special_Character)`,
  PROVIDE_PASSWORD_MAX_LENGTH: `* Password can't be more than 12 character`,
  PROVIDE_CONFIRM_PASSWORD: "*Please enter confirm password",
  PASSWORD_NOT_MATCHED: "New password and confirm password not matched",
  PROVIDE_NAME: "*Name is required",
  PROVIDE_FIRST_NAME: "*Please enter first name",
  PROVIDE_LAST_NAME: "*Please enter last name",
  PROVIDE_MOBILE_NUMBER: "*Please enter mobile number",
  PROVIDE_CONTACT_NUMBER: "*Please enter contact number",
  PROVIDE_VALID_CONTACT_NUMBER: "*Please enter valid contact number",
  PROVIDE_FAX_NUMBER: "*Please enter fax number",
  PROVIDE_FAX_NUMBER_LENGTH: "*Fax number can't be more than 10 character",
  PROVIDE_VALID_MOBILE_NUMBER: "*Please enter numbers only",
  PROVIDE_ADDRESS: "*Please enter address",
  PROVIDE_ADDRESS_LIMIT: "*Address can't be more than 200 character",
  PROVIDE_ADDRESS_LINE_1: "*Enter address line 1",
  PROVIDE_ADDRESS_LINE_2: "*Enter address line 2",
  PROVIDE_CITY: "*Enter city",
  PROVIDE_STATE: "*Enter state",
  PROVIDE_ZIPCODE: "*Enter zipcode",
  PROVIDE_VALID_ZIPCODE: "*Enter valid zipcode",
  PROVIDE_WEBSITE: "Please enter valid website (no whitespace)",
  PROVIDE_ROLE: "*Select role",
  PROVIDE_GENDER: "*Select gender",
  PROVIDE_COUNTRY_NAME: "*Enter country name ",
  PROVIDE_COUNTRY_CODE: "*Enter country code",
  PROVIDE_PHONE_CODE: "*Enter phone code ",
  PROVIDE_TIMEZONE: "*Enter Timezone",
  PROVIDE_CURRENCY: "*Enter currency",
  PROVIDE_BACKEND: "*Secret key is required",
  PROVIDE_FROUNTEND: "*Publishable key is required",
  PROVIDE_CLIENTID: "*ClientId is required",
  PROVIDE_CLIENTSECRET: "* clientSecret is required",
  PROVIDE_MODE: "*Mode is required",
  PROVIDE_FROM_EMAIL: "Please enter from email",
  PROVIDE_VALID_FROM_EMAIL: "Please enter valid from email",
  PROVIDE_PORT: "Please enter SMTP port",
  PROVIDE_VALID_PORT: "Please enter valid port number",
  PROVIDE_USER_NAME: "*Please enter user name",
  PROVIDE_HOST: "Please enter host name ",
  PROVIDE_VALID_HOST: "Please enter valid host name ",
  PROVIDE_ENCRYPTION: "Please enter encryption",
  PROVIDE_VALID_ENCRYPTION: "Please enter encryption (minimum length 3)",
  PROVIDE_FROMNAME: "Please enter from name",
  PROVIDE_VALID_FROMNAME: "Please enter from name (minimum length 5)",
  PROVIDE_APPID: "Please enter appId ",
  PROVIDE_VALID_APPID: "Please enter appId (minimum length 5)",
  PROVIDE_SMS_USER_NAME: "Please enter user name (minimum length 5)",
  PROVIDE_VALID_SMTP_USER_NAME:
    "User name can be alphanumeric with special character _ (minimum length 5)",
  PROVIDE_SMTP_USER_NAME: "Please enter user name",
  PROVIDE_SMTP_PASSWORD: "Please enter password (minimum length 5)",
  PROVIDE_SMS_PASSWORD: "Please enter password",
  PROVIDE_VALID_SMS_PASSWORD: "Please enter password (minimum length 5)",
  PROVIDE_ACCOUNTSID: "*Please enter account SID",
  PROVIDE_AUTHTOKEN: "*Enter auth token",
  PROVIDE_FBURL: "Please enter correct facebook link (no whitespace)",
  PROVIDE_TWITTER: "Please enter correct twitter link (no whitespace)",
  PROVIDE_INSTAGRAM: "Please enter correct instagram link (no whitespace)",
  PROVIDE_GITHUB: "Please enter correct github link (no whitespace)",
  PROVIDE_CODEPEN: "Please enter correct codepen link (no whitespace)",
  PROVIDE_SLACK: "Please enter correct slack link (no whitespace)",
  PROVIDE_YOUTUBEURL: "Please enter correct youtube link (no whitespace)",
  PROVIDE_LINKEDIN: "Please enter  correct linkedIn link (no whitespace)",
  PROVIDE_TIKTOK: "Please enter  correct tiktok link (no whitespace)",
  PROVIDE_FACEBOOK: "Please enter facebook link",
  PROVIDE_FBAPPID: "*Please enter facebook appId",
  PROVIDE_VALID_FBAPPID:
    "*Please enter valid facebook appId (length between 5-50 characters)",
  PROVIDE_TWITTERAPID: "*Please enter twitter appId",
  PROVIDE_VALID_TWITTERAPID:
    "*Please enter valid twitter appId (length between 5-50 characters)",
  PROVIDE_LINKEDINAPID: "*Please enter linkedIn appId",
  PROVIDE_VALID_LINKEDINAPID:
    "*Please enter valid linkedIn appId (length between 5-50 characters)",
  PROVIDE_FBAPPSECRET: "*Please enter facebook app secret",
  PROVIDE_VALID_FBAPPSECRET:
    "*Please enter valid facebook app secret (length between 5-50 characters)",
  PROVIDE_TWITTERAPSECRET: "*Please enter twitter app secret",
  PROVIDE_VALID_TWITTERAPSECRET:
    "*Please enter valid twitter app secret (length between 5-50 characters)",
  PROVIDE_LINKEDINAPSECRET: "*Please enter linkedIn app secret ",
  PROVIDE_VALID_LINKEDINAPSECRET:
    "*Please enter valid linkedIn app secret (length between 5-50 characters)",
  PROVIDE_ENVIRONMENT: "*Environment is required",
  PROVIDE_VALID_ENVIRONMENT:
    "*Provide valid environment (length between 5-50 characters)",
  PROVIDE_PUBLIC_API_ID: "*API loginId is required",
  PROVIDE_VALID_API_ID: "*Provide valid ID (length between 5-50 characters)",
  PROVIDE_TEST_TRANSACTION_KEY: "*Test transaction key is required",
  PROVIDE_VALID_TEST_TRANSACTION_KEY:
    "*Provide valid test transaction key (length between 5-50 characters)",
  PROVIDE_PUBLIC_TRANSACTION_KEY: "*Public transaction key is required",
  PROVIDE_VALID_PUBLIC_TRANSACTION_KEY:
    "*Provide valid public transaction key (length between 5-50 characters)",
  PROVIDE_GOOGLETOKEN: "Enter Google OAuth Token",
  PROVIDE_OOFLINEMSG: "Enter offline message",
  PROVIDE_EQUIPMENT_NAME: "*Equipment type name is required",
  PROVIDE_EQUIPMENT_LIMIT:
    "*Equipment type name must be maximum 100 characters",
  PROVIDE_EQUIPMENT_SIZE: "*Size is required",
  PROVIDE_EQUIPMENT_SIZE_LIMIT: "*Equipment size must be maximum 10 characters",
  PROVIDE_FREIGHT_NAME: "*Commodity type name is required",
  PROVIDE_FREIGHT_LIMIT: "*Commodity type name must be maximum 100 characters",
  PROVIDE_DRIVER_STATUS: "*Driver status is required",
  PROVIDE_DRIVER_STATUS_LIMIT: "*Driver status must be maximum 100 characters",
  PROVIDE_DRIVER_STATUS_NAME: "*Driver status name is required",
  PROVIDE_DRIVER_STATUS_NAME_LIMIT:
    "*Driver status name must be maximum 100 characters",
  PROVIDE_DRIVER_STATUS_COLOR: "*Color is required",
  PROVIDE_HAZMAT_CLASS: "*Hazmat class is required",
  PROVIDE_HAZMAT_CLASS_LIMIT:
    "*Hazmat class name must be maximum 100 characters",
  PROVIDE_FAQ_QUESTION: "*FAQ Question is required",
  PROVIDE_FAQ_QUESTION_LIMIT: "*Question must be maximum 100 characters",
  PROVIDE_FAQ_ANSWER: "*FAQ Answer is required",
  PROVIDE_FAQ_ANSWER_LIMIT: "*Answer must be maximum 500 characters",
  PROVIDE_SHIP_LINK: "*Ship link is required",
  PROVIDE_VALID_SHIP_LINK: "*Valid ship link is required",
  PROVIDE_HAUL_LINK: "*Haul link is required",
  PROVIDE_VALID_HAUL_LINK: "*Valid haul link is required",
  PROVIDE_VIDEO_LINK: "*Video link is required",
  PROVIDE_VALID_VIDEO_LINK: "*Valid video link is required",
  PROVIDE_CAREER_LINK: "*Career link is required",
  PROVIDE_VALID_CAREER_LINK: "*Valid career link is required",
  PROVIDE_IMAGE: "*Image is required",
  PROVIDE_DESIGNATION: "*Designation is required",
  PROVIDE_COUNT: "*Count is required",
  PROVIDE_TITLE: "*Title is required",
  PROVIDE_TITLE_LIMIT: "*Title must be maximum 100 characters",
  PROVIDE_HEADER_LINE: "*Header line is required",
  PROVIDE_HEADER_LIMIT: "*Header line must be maximum 100 characters",
  PROVIDE_DESCRIPTION_TEXT: "*Discription is required",
  PROVIDE_DESCRIPTION_LIMIT: "*Description must be maximum 500 characters",
  PROVIDE_METATITLE: "*Meta Title is required",
  PROVIDE_METAKEYWORD: "*Meta Keyword is required",
  PROVIDE_METADESCRIPTION: "*Meta Discription is required",
  PROVIDE_HEADERSINPPET: "Add header snippet",
  PROVIDE_FOOTERSINPPET: "Add footer snippet",
  PORVIDE_ANALYTICS: " Add Analytics snippet",
  PROVIDE_COMPANY_NAME: "Enter role ",
  PROVIDE_TEMPLATE_NAME: "Enter template name ",
  PROVIDE_TEMPLATE_SUBJECT: "Enter template subject ",
  PROVIDE_EMAIL_FROM: "Enter email id from ",
  PROVIDE_EMAIL_TEMPLATES: "Enter email templates ",
  PROVIDE_SITE_FEVICON: "Please provide Site fevicon Icon",
  PROVIDE_SITE_SMALL_LOGO: "Please provide Site Small Image Icon",
  PROVIDE_SITE_LARGE_LOGO: "Please provide Site Large Image Icon",
  PROVIDE_LIMITED_OFFLINE_MSG: "Message length should be under 50 characters",
  PROVIDE_METATITLE_LIMIT: "*Meta title must be maximum 100 characters",
  PROVIDE_METADESCRIPTION_LIMIT:
    "*Meta description must be maximum 200 characters",
  PROVIDE_METAKEYWORDS_LIMIT: "*Meta keywords must be maximum 200 characters",
  START_END_DATE_ERROR:
    "End date and time should be greater than start date and time",
  START_LESS_THAN_CURRENT:
    "Start date and time should be greater than current date and time",
  END_LESS_THAN_CURRENT:
    "End date and time should be greater than current date and time",
  START_END_DATE_EQUAL_ERROR: "Start and end date time can't be same",
  PROVIDE_ROLENAME: "Please enter Role name",
  PROVIDE_DESCRIPTION: "Please enter Description",
  ROLE_NAME_LIMIT: "Maximum characters are 20",
  ROLE_DESC_LIMIT: "Maximum characters are 200",
  NO_RECORD_FOUND: "No Records Found!",
  PROVIDE_NEWSLETTER_TEXT: "*Please enter newsletter text",
  FEEDBACK_REPLY_MESSAGE: "Reply message required",
  PROVIDE_LOADTYPE: "Select load type",
  PROVIDE_EQUIPMENT_TYPE_NAME: "Select equipment name",
  PROVIDE_PICKUP_LOACTION: "Please type pickup location",
  PROVIDE_DROPOFF_LOACTION: "Please type drop-off location",
  PROVIDE_MILES: "Select miles",
  PROVIDE_WEIGHT: "Select weight",
  PROVIDE_ESTIMATED_LOAD_PRICE: "Please type estimate load Price",
  PROVIDE_ESTIMATED_BID_SPRICE: "Please Type estimate bid Price",
  UPLOAD_VALID_IMAGE_FORMAT: "Please upload JPG/JPEG/PNG files only",
  UPLOAD_VALID_MEDIA_FORMAT:
    "Please upload JPG/JPEG/PNG/MP4/AVI/DOCX/XML files only",
  PROVIDE_VALID_LINK: "Enter valid link",
  PROVIDE_VALID_LINK_LIMIT: "Link must be maximum 100 characters",
  PROVIDE_RADIUS_RANGE: "*Radius range is required",
  FACTORING_COMPANY_REQUIRED: "Select factoring company Yes/No",
  W9_REQUIRED: "W9 field must be checked",
  ACH_INSTRUCTION: "ACH Instruction is required*",
  TERM_DOCUMENTS: "Requiered!",
  MISC_CHARGE: "Misc Charge is Requiered!",
  MISC_NOTES: "Notes is Requiered!",
  COMMISSION_VALUE_MESSAGE: "Commission must be 0% to 100%",
  UPLOAD_VALID_VIDEO_FORMAT: "Please upload video mp4/webm/flv/avi format only",
  PAY_TERM_NAME: "Payterm Type is requiered",
  PAY_TERM_PERCENTAGE: "Percentage is requiered",
  PAY_TERM_PERCENTAGE_MIN_MAX: "Percentage is must between 0 to 100%",
  PAY_TERM_NAME: "Duplicate Name Found:- ",
};
