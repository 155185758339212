/***** 
@Purpose : Object containing paths for application
@Parameter : {}
@Author : INIC
*****/

export const paths = {
  LOGIN: "/login",
  EDIT_PROFILE: "/edit-profile",
  FORGOT_PASSWORD: "/forgotpassword",
  RESET_PASSWORD: "/reset-password",
  DASHBOARD: "/dashboard",
  VIEW_USER_DETAIL: "/dashboard/userdetail",
  VIEW_LOAD_DETAILS: "/dashboard/loaddetail",
  SHIPPER_GRAPH: "/dashboard/shipper-graph",
  CARRIER_GRAPH: "/dashboard/carrier-graph",
  LOADS_GRAPH: "/dashboard/loads-graph",
  EARNINGS: "/dashboard/earnings",
  ROLESLIST: "/manageteam/roles",
  ADDROLE: "/manageteam/roles/addrole",
  EDITROLE: "/manageteam/roles/editrole/:slug",
  MANAGETEAMUSERS: "/manageteam/users",
  ADDUSERS: "/manageteam/users/adduser",
  EDITUSERS: "/manageteam/users/edituser/:slug",
  DETAILSUSERS: "/manageteam/users/detailsuser/:slug",
  SHIPPERS: "/shippers",
  SHIPPER_PROFILE: "/shipper/shipper-detail",
  SHIPPER_LOADS: "/shipper/shipper-loads",
  SHIPPER_LOAD_DETAILS: "/shipper/shipper-loads/load-detail",
  TENDERS: "/shipper/tenders",
  LOAD_POST: "/shipper/load-post",
  CARRIERS: "/carriers",
  CARRIER_PROFILE: "/carrier/carrier-detail",
  CARRIER_RATING: "/carrier/carrier-rating",
  DISPATCHER_DETAILS: "/carrier/dispatcher-details",

  CARRIER_LOADS: "/carrier/carrier-loads",
  CARRIER_LOAD_DETAIL: "/carrier/carrier-loads/load-detail",
  CARRIER_EQUIPMENT: "/carrier/carrier-equipment",
  CARRIER_EQUIPMENT_DETAIL: "/carrier/carrier-equipment/equipment-detail",
  CARRIER_DRIVER: "/carrier/carrier-driver",
  CARRIER_DRIVER_DETAIL: "/carrier/carrier-driver/driver-details",
  NOTIFICATION: "/notification",
  NEWSLETTER: "/newsletter",
  NEWSLETTER_REPLAY: "/NewsLetter/NewsLetterSend",
  UNSUBSCRIBE_USER: "/Unsubscribe_User",
  MASTER: "/master",
  LOAD_BOARD: "/load-board",
  LOAD_TRACKING: "/load-tracking",
  TESTDATA: "/test-data",
  FAQ: "/faq",
  FREIGHT_TYPE_LIST: "/freight-type-list",
  DRIVER_STATUS_LIST: "/driver-status-list",
  ADDEDITCMSPRIVACY: "/add-update-cms-privacy",
  ADDEDITCMSTERMS: "/add-update-cms-terms",
  ADDEDITCMSDISCLAIMER: "/add-update-cms-disclaimer",
  ADDEDITCMS: "/add-update-cms",
  ADDEDITCMSHOME: "/add-update-cms-home",
  ADDEDITCMSABOUT: "/add-update-cms-about",
  FEEDBACK_COMPLAINS: "/feedback-complains",
  FEEDBACK_COMPLAINS_DETAILS: "/feedback-complains/feedback-complains-details",
  PLAN_CONFIGURATION: "/plan-configuration",
  LOAD_ESTIMATES: "/load-estimates",
  LOAD_REPORTS: "/load-report",
  LOAD_REPORTS_LOAD_DETAILS: "/load-report/load-details",
  SETTINGS_GENERAL: "/settings/general-settings",
  SETTINGS_SOCIALMEDIA: "/settings/social-media",
  SETTINGS_EMAIL: "/settings/email-settings",
  SETTINGS_IMPORTED_LINKS: "/settings/imported-links",
  MANUAL_LOAD_DETAIL: "/loadboard/manual/load-detail",
  AUCTION_LOAD_DETAIL: "/loadboard/auction/load-detail",
  ARCHIVE_LOAD_DETAIL: "/loadboard/archive/load-detail",
  CHAT: "/chat",
  ACCOUNT_INVOICES: "/accounting/account-invoice",
  ACCOUNT_RECEIVABLE: "/accounting/account-receivable",
  ACCOUNT_PAYABLE: "/accounting/account-payable",
  ACCOUNT_REPORTS: "/accounting/account-reports",
  PAGE_NOTFOUND: "*",
};
