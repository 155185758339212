//chat
export const SAVE_USER_SESSION = "SAVE_USER_SESSION"
export const ACTIVE_DIALOG = "ACTIVE_DIALOG"
export const DIALOG_LIST = "DIALOG_LIST"
export const CHAT_USER_INFO = "CHAT_USER_INFO"
export const CHAT_HISTORY = "CHAT_HISTORY"
export const CHAT_CREDS = "CHAT_CREDS"
export const QUICKBLOX_INFO = "QUICKBLOX_INFO"
export const CREATED_DIALOG = "CRATED_DIALOG"
export const RESET_CHAT = "RESET_CHAT"

// role
export const USER_ROLE_PERMISSIONS = "USER_ROLE_PERMISSIONS"
