import { combineReducers } from "redux"
import Admin from "./Admin"
import chatInfo from "./chat"
import PermissionsReducer from "./role"

/******************* 
@Purpose : Used for combine all reducer in single place
@Parameter : {Admin}
@Author : INIC
******************/
export default combineReducers({
  admin: Admin,
  chat: chatInfo,
  permission: PermissionsReducer,
})
