const initialState = { permissions: [] }

const PermissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_ROLE_PERMISSIONS":
      return Object.assign({}, state, {
        permissions: action?.payload,
      })

    default:
      return state
  }
}
export default PermissionsReducer
