import { Route, Redirect } from "react-router-dom";
import { Authorization } from "../utils/Functions";
import { paths } from "./constants";

/******************* 
@Purpose : Used to declare private route
@Parameter : {}
@Author : INIC
******************/
export const PrivateRoute = ({
  component: Component,
  privateRoute,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      return privateRoute ? (
        Authorization() ? (
          <Component {...props} />
        ) : (
          <Redirect to={paths.LOGIN} />
        )
      ) : props.location.pathname === paths.LOGIN ? (
        !Authorization() ? (
          <Component {...props} />
        ) : (
          <Redirect to={paths.DASHBOARD} />
        )
      ) : (
        <Component {...props} />
      );
    }}
  />
);
