import swal from "sweetalert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IMG_URL, PUBLIC_FILE_URL } from "../config";
import errorMessages from "./ErrorMessages";
import moment from "moment";
import { Badge } from "react-bootstrap";
import {
  activity_status,
  document_description,
  fileType,
  GRAPH_MONTHS,
  GRAPH_WEEKLY,
  LOAD_POST,
  load_status,
  SUPPORTED_FORMATS,
  TempratureEnable,
} from "./constants";
import { ExcelRenderer } from "react-excel-renderer";

/******************* 
@Purpose : Store Data To local Storage
@Parameter : key, value
@Author : INIC
******************/
export const setItem = (key, value) => {
  localStorage.setItem(key, value);
};
/******************* 
@Purpose : Get Data From local Storage
@Parameter : key
@Author : INIC
******************/
export const getItem = (key) => {
  return localStorage.getItem(key);
};
/******************* 
@Purpose : Remove Data in local Storage
@Parameter : key
@Author : INIC
******************/
export const removeItem = (key) => {
  localStorage.removeItem(key);
};
/******************* 
@Purpose : Email Validation
@Parameter : email
@Author : INIC
******************/
export const validateEmail = (email) => {
  const pattern = new RegExp(
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|(^\d{10}$)+$/
  );
  return pattern.test(email);
};

/******************* 
@Purpose : App ID and secret Validation
@Parameter : id
@Author : INIC
******************/
export const validateIDSecret = (id) => {
  const pattern = new RegExp(/^[a-zA-Z0-9_]{5,50}$/);
  return pattern.test(id);
};
/******************* 
@Purpose : Password Validation
@Parameter : pass
@Author : INIC
******************/
export const validatePassword = (pass) => {
  var pattern = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-!$@#%^&*()_+|~=`{}\[\]:";'<>?,.\/])[A-Za-z\d-!$@#%^&*()_+|~=`{}\[\]:";'<>?,.\/]{8,12}$/
  );
  return pattern.test(pass);
};
/******************* 
@Purpose : Username Validation
@Parameter : name
@Author : INIC
******************/
export const validateUsername = (name) => {
  var pattern = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!_%-*?&#])[A-Za-z\d@$!_%-*?&#]{5,}$/
  );
  return pattern.test(name);
};
/******************* 
@Purpose : Port Validation
@Parameter : port
@Author : INIC
******************/
export const validatePort = (port) => {
  var pattern = new RegExp(/^([0-9]){3,4}$/);
  return pattern.test(port);
};
/******************* 
@Purpose : Hostname Validation
@Parameter : name
@Author : INIC
******************/
export const validateHostName = (name) => {
  var pattern = new RegExp(
    /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)+([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$/
  );
  return pattern.test(name);
};
/******************* 
@Purpose : Mobile Number Validation
@Parameter : mobileNo
@Author : INIC
******************/
export const validateMobileNumber = (mobileNo) => {
  var pattern = new RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );
  return pattern.test(mobileNo);
};
/******************* 
@Purpose : First and Last Name Validation
@Parameter : name
@Author : INIC
******************/
export const validateName = (name) => {
  const pattern = new RegExp(/^[a-z ,.'-]+$/i);
  return pattern.test(name);
};
/******************* 
@Purpose : Character Validation
@Parameter : e
@Author : INIC
******************/
export const allowChar = (e) => {
  const pattern = new RegExp(/^[a-zA-Z\s]{0,255}$/);
  return pattern.test(e);
};
/******************* 
@Purpose : URL Validation
@Parameter : url
@Author : INIC
******************/
export const validateURL = (url) => {
  const pattern = new RegExp(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return pattern.test(url);
};
/*******************
@Purpose :  Validation URL
@Parameter : url
@Author : INIC
******************/
export const urlValidate = (url) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(url);
};
/******************* 
@Purpose : Number Validation
@Parameter : number
@Author : INIC
******************/
export const allowNumbers = (number) => {
  const pattern = new RegExp(/^[0-9\b]+$/);
  return pattern.test(number);
};
/******************* 
@Purpose : Used for show modal notification
@Parameter : text, type, timer, position, buttons, className
@Author : INIC
******************/
export const showModalNotification = (
  text,
  type = "success",
  timer = 2500,
  position = "center",
  buttons = false
) => {
  swal({
    position: position,
    icon: type,
    text: text,
    buttons: buttons,
    timer: timer,
    className: "custom-toaster",
  });
};
/******************* 
@Purpose : Used for show message notification
@Parameter : text, type, autoClose, position
@Author : INIC
******************/
export const showMessageNotification = (
  text,
  type = "success",
  autoClose = 1500,
  position = "top-right"
) => {
  toast[type](text, {
    position: position,
    autoClose: autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
/******************* 
@Purpose : Pincode Validation
@Parameter : msg
@Author : INIC
******************/
export const validatePincodeNumber = (mobileNo) => {
  var pattern = new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/);
  return pattern.test(mobileNo);
};
/******************* 
@Purpose : Used for change langauge
@Parameter : body
@Author : INIC
******************/
export const changeLanguage = (body) => async (dispatch) => {
  setItem("language", body.language);
  const payload = {
    data: body.language,
  };
  dispatch({ type: "language", payload });
};

/*** 
  @Purpose : Used to check whether user is logged in or not
  @Parameter : key
  @Author : INIC
  **/
export const Authorization = () => {
  return getItem("accessToken") ? true : false;
};

/******************* 
  @Purpose : Used for sidebar open
  @Parameter : {}
  @Author : INIC
  ******************/
export const SidebarOpen = (param = true) => {
  return param && document.body.classList.remove("sidebar-open");
};
/*******************
  @Purpose : Used for get header
  @Parameter : {}
  @Author : INIC
  ******************/
export const getHeader = (formData = false) => {
  return {
    "Content-Type": formData ? "multipart/form-data" : "application/json",
    Accept: "application/json",
    authorization: getItem("accessToken"),
  };
};
/*******************
  @Purpose : Used for service validate2
  @Parameter : {}
  @Author : INIC
  ******************/
export const isServiceValid2 = (services) => {
  return services.every(
    (service) =>
      service.errorMessageTitle === "" &&
      service.errorMessageDesc === "" &&
      service.errorMessageLogo === ""
  );
};
/*******************
  @Purpose : Used for service object2
  @Parameter : {}
  @Author : INIC
  ******************/
export const šerviceObjectValidate2 = (services) => {
  return services?.map((singleService) => {
    if (singleService.service_list_title === "") {
      singleService.errorMessageTitle = errorMessages.PROVIDE_TITLE;
    } else if (singleService.service_list_title.length > 100) {
      singleService.errorMessageTitle = errorMessages.PROVIDE_TITLE_LIMIT;
    } else {
      singleService.errorMessageTitle = "";
    }
    if (singleService.service_list_description === "") {
      singleService.errorMessageDesc = errorMessages.PROVIDE_DESCRIPTION_TEXT;
    } else if (singleService.service_list_description.length > 200) {
      singleService.errorMessageDesc = errorMessages.PROVIDE_DESCRIPTION_LIMIT;
    } else {
      singleService.errorMessageDesc = "";
    }
    if (singleService.service_list_logo === "") {
      singleService.errorMessageLogo = errorMessages.PROVIDE_IMAGE;
    } else {
      singleService.errorMessageLogo = "";
    }
    return singleService;
  });
};
/*******************
  @Purpose : Used for service validate3
  @Parameter : {}
  @Author : INIC
  ******************/
export const isServiceValid3 = (services) => {
  return services.every(
    (service) =>
      service.errorMessageTitle === "" &&
      service.errorMessageDesc === "" &&
      service.errorMessageLogo === ""
  );
};
/*******************
  @Purpose : Used for service object3
  @Parameter : {}
  @Author : INIC
  ******************/
export const šerviceObjectValidate3 = (services) => {
  return services?.map((singleService) => {
    if (singleService.cms_section3_title === "") {
      singleService.errorMessageTitle = errorMessages.PROVIDE_TITLE;
    } else if (singleService.cms_section3_title.length > 100) {
      singleService.errorMessageTitle = errorMessages.PROVIDE_TITLE_LIMIT;
    } else {
      singleService.errorMessageTitle = "";
    }
    if (singleService.cms_section3_description === "") {
      singleService.errorMessageDesc = errorMessages.PROVIDE_DESCRIPTION_TEXT;
    } else if (singleService.cms_section3_description.length > 200) {
      singleService.errorMessageDesc = errorMessages.PROVIDE_DESCRIPTION_LIMIT;
    } else {
      singleService.errorMessageDesc = "";
    }
    if (singleService.cms_section3_logo === "") {
      singleService.errorMessageLogo = errorMessages.PROVIDE_IMAGE;
    } else {
      singleService.errorMessageLogo = "";
    }
    return singleService;
  });
};

/*******************
  @Purpose : Used for service validate5
  @Parameter : {}
  @Author : INIC
  ******************/
export const isServiceValid5 = (services) => {
  return services.every((service) => service.errorMessageLogo === "");
};
/*******************
  @Purpose : Used for service object5
  @Parameter : {}
  @Author : INIC
  ******************/
export const šerviceObjectValidate5 = (services, type) => {
  return services?.map((singleService) => {
    if (singleService.partners_list_logo === "") {
      singleService.errorMessageLogo = errorMessages.PROVIDE_IMAGE;
    } else {
      singleService.errorMessageLogo = "";
    }
    return singleService;
  });
};

/*******************
  @Purpose : Used for service About validate3
  @Parameter : {}
  @Author : INIC
  ******************/
export const isServiceValidAbout3 = (services) => {
  return services.every(
    (service) =>
      service.errorMessageTitle === "" &&
      service.errorMessageCount === "" &&
      service.errorMessageLogo === ""
  );
};
/*******************
  @Purpose : Used for service About object3
  @Parameter : {}
  @Author : INIC
  ******************/
export const šerviceObjectValidateAbout3 = (services) => {
  return services?.map((singleService) => {
    if (singleService.cms_section3_title === "") {
      singleService.errorMessageTitle = errorMessages.PROVIDE_TITLE;
    } else if (singleService.cms_section3_title.length > 100) {
      singleService.errorMessageTitle = errorMessages.PROVIDE_TITLE_LIMIT;
    } else {
      singleService.errorMessageTitle = "";
    }
    if (singleService.cms_section3_count === "") {
      singleService.errorMessageCount = errorMessages.PROVIDE_COUNT;
    } else {
      singleService.errorMessageCount = "";
    }
    if (singleService.cms_section3_logo === "") {
      singleService.errorMessageLogo = errorMessages.PROVIDE_IMAGE;
    } else {
      singleService.errorMessageLogo = "";
    }
    return singleService;
  });
};

/*******************
  @Purpose : Used for service About validate4
  @Parameter : {}
  @Author : INIC
  ******************/
export const isServiceValidAbout4 = (services) => {
  return services.every((service) => service.errorMessageLogo === "");
};
/*******************
  @Purpose : Used for service About object4
  @Parameter : {}
  @Author : INIC
  ******************/
export const šerviceObjectValidateAbout4 = (services) => {
  return services?.map((singleService) => {
    if (singleService.cms_section4_image === "") {
      singleService.errorMessageLogo = errorMessages.PROVIDE_IMAGE;
    } else {
      singleService.errorMessageLogo = "";
    }
    return singleService;
  });
};
/*******************
  @Purpose : Used for service About validate3
  @Parameter : {}
  @Author : INIC
  ******************/
export const isServiceValidAbout5 = (services) => {
  return services.every(
    (service) =>
      service.errorMessageTitle === "" &&
      service.errorMessageDesig === "" &&
      service.errorMessageDesc === "" &&
      service.errorMessageLogo === ""
  );
};
/*******************
  @Purpose : Used for service About object3
  @Parameter : {}
  @Author : INIC
  ******************/
export const šerviceObjectValidateAbout5 = (services) => {
  return services?.map((singleService) => {
    if (singleService.team_member_name === "") {
      singleService.errorMessageTitle = errorMessages.PROVIDE_NAME;
    } else {
      singleService.errorMessageTitle = "";
    }
    if (singleService.team_member_designation === "") {
      singleService.errorMessageDesig = errorMessages.PROVIDE_DESIGNATION;
    } else {
      singleService.errorMessageDesig = "";
    }
    if (singleService.team_member_description === "") {
      singleService.errorMessageDesc = errorMessages.PROVIDE_DESCRIPTION_TEXT;
    } else if (singleService.team_member_description.length > 200) {
      singleService.errorMessageDesc = errorMessages.PROVIDE_DESCRIPTION_LIMIT;
    } else {
      singleService.errorMessageDesc = "";
    }
    if (singleService.team_member_image === "") {
      singleService.errorMessageLogo = errorMessages.PROVIDE_IMAGE;
    } else {
      singleService.errorMessageLogo = "";
    }
    return singleService;
  });
};

/*******************
  @Purpose : Used to get document name from document url
  @Parameter : {}
  @Author : INIC
  ******************/
export const getDocName = (docfile) => {
  return docfile.split("documents/")[1];
};

/*******************
  @Purpose : Used for image validation
  @Parameter : {}
  @Author : INIC
  ******************/
export const isImageValidation = (imgFile) => {
  if (
    !(SUPPORTED_FORMATS.IMAGES || SUPPORTED_FORMATS.VIDEOS)?.includes(imgFile)
  ) {
    showMessageNotification(errorMessages.UPLOAD_VALID_IMAGE_FORMAT, "error");
    return false;
  } else {
    return true;
  }
};

/*******************
  @Purpose : Used for image and video validation
  @Parameter : {}
  @Author : INIC
  ******************/
export const isVideoValidation = (imgFile) => {
  if (!SUPPORTED_FORMATS.VIDEOS.includes(imgFile)) {
    showMessageNotification(errorMessages.UPLOAD_VALID_VIDEO_FORMAT, "error");
    return false;
  } else {
    return true;
  }
};

/*******************
  @Purpose : Used for media validation
  @Parameter : {}
  @Author : INIC
  ******************/
export const isMediaValidation = (imgFile) => {
  if (
    ![
      "image/png", // for image .png
      "image/jpeg", // for image .jpeg
      "image/jpg", // for image .jpg
      "video/mp4", // for video .mp4
      "video/avi", // for video .avi
      "application/pdf", // for .pdf file
      "application/msword", // for .doc file
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // for .docx file
      "application/vnd.ms-excel", // for .xls file
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // for .xlsx file
    ].includes(imgFile)
  ) {
    showMessageNotification(errorMessages.UPLOAD_VALID_MEDIA_FORMAT, "error");
    return false;
  } else {
    return true;
  }
};
/*******************
  @Purpose : Use to get full image URL
  @Parameter : {}
  @Author : INIC
  ******************/
export const getImageURL = (filePath) => {
  return `${IMG_URL + filePath}`;
};
/*******************
  @Purpose : Use to get full image URL Condition
  @Parameter : {}
  @Author : INIC
  ******************/
export const getImageURLCondition = (image, noImage) => {
  return image ? image : "/assets/images/no-image.png";
};

/*******************
  @Purpose : Use to set Currency Separator
  @Parameter : {}
  @Author : INIC
  ******************/
export const currencySeparator = (num, div = ",") => {
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, div);
};

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
};

export const activeMonthClass = (activeMonth, month) => {
  return activeMonth === month ? "btn btn-light active" : "btn btn-light";
};

export const activeFilterPopup = (filterOpen) => {
  return filterOpen
    ? "custom-dropdown filter-data-dropdown position-static ml-2 open"
    : "custom-dropdown filter-data-dropdown position-static ml-2";
};

export const activeCarrierProfileTab = (active) => {
  return active
    ? "btn btn-default minW-md-0 btn-bg-white mr-3 mb-3 active"
    : "btn btn-default minW-md-0 btn-bg-white mr-3 mb-3";
};

export const dateFormatter = (param1, param2, format = "MM/DD/YYYY, LT") => {
  return moment(param1 ? param1 : param2).format(globalDateFormater(format));
};
export const dateFormatterUTC = (param1, format) => {
  return moment.utc(param1).format(globalDateFormater(format));
};

export const longDateFormatter = (
  param1,
  param2,
  format = "MMMM D, YYYY, LT"
) => {
  return moment.utc(param1 ? param1 : param2).format(format);
};

export const commonTernary = (condition, param1, param2) => {
  return condition ? param1 : param2;
};

/******************* 
  @Purpose : To make table headers
  @Parameter : {column name array}
  @Author : INIC
  ******************/
export const TableHeaders = (arr) => {
  return (
    <tr>
      {arr.map((e) => {
        return (
          <th key={e}>
            <b>{e}</b>
          </th>
        );
      })}
    </tr>
  );
};

/******************* 
  @Purpose :Badge 
  @Parameter : {key, className}
  @Author : INIC
  ******************/
export const BadgeComp = (key, className = "secondary") => {
  return (
    <Badge variant={className} className="badge badge-pill pt-2">
      {key}
    </Badge>
  );
};

/******************* 
  @Purpose :Used for load status badge
  @Parameter : {loadStatus}
  @Author : INIC
  ******************/
export const loadStatus = (detail) => {
  switch (detail.loadStatus) {
    case load_status.posted:
      return BadgeComp(load_status.posted, "primary");

    case load_status.bidposted:
      return BadgeComp(load_status.bidposted, "primary");

    case load_status.pickedUp:
      return BadgeComp(load_status.pickedUp, "primary");

    case load_status.assigned:
      return BadgeComp(load_status.assigned, "primary");

    case load_status.draft:
      return BadgeComp(load_status.draft);

    case load_status.accepted:
      return BadgeComp(load_status.accepted, "success");

    case load_status.cancelled:
      return BadgeComp(load_status.cancelled, "danger");

    case load_status.unposted:
      return BadgeComp(load_status.unposted);

    case load_status.shared:
      return BadgeComp(load_status.shared);

    case load_status.delivered:
      return BadgeComp(load_status.delivered, "danger");

    default:
      break;
  }
};

/*******************
  @Purpose : Used for Check phone input error
  @Parameter : {}
  @Author : INIC
  ******************/
export const isCheckPhoneInputErrMsg = (touched, errors) => {
  if (touched) {
    return `control-wrap ${errors ? "error-block" : "success-block"}`;
  } else {
    return "control-wrap";
  }
};

export const conditionalTernary = (param1, param2) => {
  return param1 ? param1 : param2;
};

/******************* 
  @Purpose :Used for activity status in load document
  @Parameter : {loadStatus}
  @Author : INIC
  ******************/
export const activityStatus = (loadDocumentsDetails) => {
  switch (loadDocumentsDetails.activityStatus) {
    case activity_status.PICKUP:
      return `${document_description.PICKUP_DESCRIPTION} ${dateFormatter(
        loadDocumentsDetails.createdAt
      )}`;

    case activity_status.DELIVERED:
      return `${document_description.DELIVERED_DESCRIPTION} ${dateFormatter(
        loadDocumentsDetails.createdAt
      )}`;

    case activity_status.REJECTED:
      return `${document_description.REJECTED_DESCRIPTION} ${dateFormatter(
        loadDocumentsDetails.createdAt
      )}`;

    case activity_status.ACCEPTED:
      return `${document_description.ACCEPTED_DESCRIPTION} ${dateFormatter(
        loadDocumentsDetails.createdAt
      )}`;

    default:
      break;
  }
};

/******************** 
@purpose : Used to set countdown end timing
@Parameter : { timezone, moment, date, time, cn }
@Author : INIC
******************/

export const countEndTiming = (moment, time) => {
  let endTime = moment.utc(new Date(time).getTime() + 30 * 60000).toDate();
  return endTime;
};

/******************** 
@purpose : Used for RCS download
@Parameter : { data }
@Author : INIC
******************/
export const handleFileDownload = (data) => {
  let name = data.split("/").pop();
  let file = `${PUBLIC_FILE_URL}${data}`;
  fetch(file)
    .then((res) => res.blob())
    .then((data) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(fileType.DOWNLOAD, name);
      link.click();
    });
};

/******************** 
@purpose : Used for  download csv files
@Parameter : { data }
@Author : INIC
******************/
export const handleFileExportCsv = (data) => {
  let name = data.split("/").pop();
  let file = `${PUBLIC_FILE_URL}${data}`;
  fetch(file)
    .then((res) => res.blob())
    .then((data) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(fileType.DOWNLOAD, name);
      link.click();
    });
};

/******************** 
@purpose : Excel format document converter
@Parameter : { data }
@Author : INIC
******************/
export const handleFileDownloadInvoiceListning = async (data) => {
  let file = `${PUBLIC_FILE_URL}${data}`;
  const api = await fetch(file);
  const blob = await api.blob();
  try {
    const excel = await ExcelRenderer(blob);
    return excel;
  } catch (err) {
    console.log(err);
  }
};

export function pickDropInitData(type) {
  const timeNow = moment().format("HH:mm");
  const timeAfterFive = moment().add(5, "hours").format("HH:mm");
  const minTime = type === "dropOff" ? timeAfterFive : timeNow;
  return {
    dateRange: false,
    dateFrom: new Date(),
    dateTo: moment(new Date()).add(1, "d").toDate(),
    time: minTime,
    timeTo: minTime,
    buildingName: "",
    location: [{ name: "", lat: "", lng: "" }],
    refType: "auto",
    refNumber: "",
    lat: "",
    lng: "",
    pickupFSC: "",
    dropOffFSC: "",
    dropOffAppointment: "",
    pickupAppointment: "",
  };
}

export const getLoadPostTransformToFormData = (
  postData,
  loadPostData,
  loadStatus,
  singleLoadInfo,
  isConfirmLocations
) => {
  //DROP OFF
  const dropOffTime = loadPostData[LOAD_POST.DROP_OFF][LOAD_POST.TIME];
  const dropOffTimeTo = loadPostData[LOAD_POST.DROP_OFF][LOAD_POST.TIME_TO];
  const dropOffDateFrom = loadPostData[LOAD_POST.DROP_OFF][LOAD_POST.DATE_FROM];
  const dropOffDateTo = loadPostData[LOAD_POST.DROP_OFF][LOAD_POST.DATE_TO];
  const dropOffLocation = postData[LOAD_POST.DROP_OFF][LOAD_POST.LOCATION];
  const dropOffRefNumber =
    loadPostData[LOAD_POST.DROP_OFF][LOAD_POST.REF_NUMBER];
  const dropOffRefType = loadPostData[LOAD_POST.DROP_OFF][LOAD_POST.REF_TYPE];
  const dropOffBuildingName = postData[LOAD_POST.DROP_OFF].buildingName;
  const dropOffLat = postData[LOAD_POST.DROP_OFF].lat;
  const dropOffLng = postData[LOAD_POST.DROP_OFF].lng;
  const dropOffFSC = loadPostData[LOAD_POST.DROP_OFF].dropOffFSC;
  const dropOffAppointment =
    loadPostData[LOAD_POST.DROP_OFF].dropOffAppointment;

  // PICK UP
  const pickUpTime = loadPostData[LOAD_POST.PICK_UP][LOAD_POST.TIME];
  const pickUpTimeTo = loadPostData[LOAD_POST.PICK_UP][LOAD_POST.TIME_TO];
  const pickUpDateFrom = loadPostData[LOAD_POST.PICK_UP][LOAD_POST.DATE_FROM];
  const pickUpDateTo = loadPostData[LOAD_POST.PICK_UP][LOAD_POST.DATE_TO];
  const pickUpLocation = postData[LOAD_POST.PICK_UP][LOAD_POST.LOCATION];
  const pickUpRefNumber = loadPostData[LOAD_POST.PICK_UP][LOAD_POST.REF_NUMBER];
  const pickUpRefType = loadPostData[LOAD_POST.PICK_UP][LOAD_POST.REF_TYPE];
  const pickUpBuildingName = postData[LOAD_POST.PICK_UP].buildingName;
  const pickUpLat = postData[LOAD_POST.PICK_UP].lat;
  const pickUpLng = postData[LOAD_POST.PICK_UP].lng;
  const pickupFSC = loadPostData[LOAD_POST.PICK_UP].pickupFSC;
  const pickupAppointment = loadPostData[LOAD_POST.PICK_UP].pickupAppointment;

  // LOAD DETAIL
  const equipmentSize =
    postData[LOAD_POST.LOAD_DETAIL][LOAD_POST.EQUIPMENT_SIZE];
  const equipmentTypeId =
    loadPostData[LOAD_POST.LOAD_DETAIL][LOAD_POST.EQUIPMENT_ID];
  const equipmentTypeName =
    loadPostData[LOAD_POST.LOAD_DETAIL][LOAD_POST.EQUIPMENT_TYPE];
  const temperature =
    loadPostData[LOAD_POST.LOAD_DETAIL][LOAD_POST.TEMPERATURE];
  const preCoolTemperature =
    loadPostData[LOAD_POST.LOAD_DETAIL][LOAD_POST.PRE_COOL_TEMPERATURE];

  const loadType = loadPostData[LOAD_POST.LOAD_DETAIL][LOAD_POST.LOAD_TYPE];

  //other
  const notesToCarrier = postData[LOAD_POST.NOTES_TO_CARRIER];
  const offerPrice = parseInt(postData[LOAD_POST.OFFER_PRICE]);
  const shipperId = loadPostData.shipperId;

  return {
    dropOffDate: !loadPostData[LOAD_POST.DROP_OFF][LOAD_POST.DATE_RANGE]
      ? handleDateFormat(dropOffDateFrom, dropOffTime)
      : null,
    dropOffTimeTo: !loadPostData[LOAD_POST.PICK_UP][LOAD_POST.DATE_RANGE]
      ? dropOffTimeTo
      : null,
    dropOffDateFrom: loadPostData[LOAD_POST.DROP_OFF][LOAD_POST.DATE_RANGE]
      ? handleDateFormat(dropOffDateFrom, dropOffTime)
      : null,
    dropOffDateTo: loadPostData[LOAD_POST.DROP_OFF][LOAD_POST.DATE_RANGE]
      ? handleDateFormat(dropOffDateTo, dropOffTimeTo)
      : null,
    dropOffBuildingName,
    dropOffLatitude: dropOffLat,
    dropOffLocation,
    dropOffLongitude: dropOffLng,
    dropOffReferenceNumber:
      dropOffRefType === LOAD_POST.MANUAL ? dropOffRefNumber : null,
    dropOffReferenceNumberType: dropOffRefType,
    equipmentSize,
    equipmentTypeId,
    equipmentTypeName,
    freights: getFreightsPostData(loadPostData[LOAD_POST.FREIGHT]),
    loadStatus,
    confirmLocation: isConfirmLocations,
    loadType,
    notesToCarrier,
    offerPrice,
    particularDateDropUp: true,
    particularDatePickUp: true,
    pickUpDate: !loadPostData[LOAD_POST.PICK_UP][LOAD_POST.DATE_RANGE]
      ? handleDateFormat(pickUpDateFrom, pickUpTime)
      : null,
    pickUpTimeTo: !loadPostData[LOAD_POST.PICK_UP][LOAD_POST.DATE_RANGE]
      ? pickUpTimeTo
      : null,
    pickUpDateFrom: loadPostData[LOAD_POST.PICK_UP][LOAD_POST.DATE_RANGE]
      ? handleDateFormat(pickUpDateFrom, pickUpTime)
      : null,
    pickUpDateTo: loadPostData[LOAD_POST.DROP_OFF][LOAD_POST.DATE_RANGE]
      ? handleDateFormat(pickUpDateTo, pickUpTimeTo)
      : null,
    pickUpBuildingName,
    pickUpLatitude: pickUpLat,
    pickUpLocation,
    pickUpLongitude: pickUpLng,
    pickUpReferenceNumber:
      pickUpRefType === LOAD_POST.MANUAL ? pickUpRefNumber : null,
    pickUpReferenceNumberType: pickUpRefType,
    temperature: TempratureEnable.some((el) => equipmentTypeName?.includes(el))
      ? temperature
      : null,
    preCoolTemperature: TempratureEnable.some((el) =>
      equipmentTypeName?.includes(el)
    )
      ? preCoolTemperature
      : null,
    shipperId: shipperId,
    pickupFSC: pickupFSC,
    pickupAppointment: pickupAppointment,
    dropOffFSC: dropOffFSC,
    dropOffAppointment: dropOffAppointment,
  };
};

export const getFreightsPostData = (freightsData) => {
  return freightsData.map((sf) => {
    return {
      description: sf[LOAD_POST.DIMENSIONS][LOAD_POST.DESCRIPTION],
      freightType: sf[LOAD_POST.FREIGHT_TYPE],
      commodityType: sf[LOAD_POST.COMMODITY_TYPE],
      loadValue: sf[LOAD_POST.LOAD_VALUE],
      freightTypeId: sf[[LOAD_POST.FREIGHT_TYPE_ID]],
      height: parseInt(sf[LOAD_POST.DIMENSIONS][LOAD_POST.HEIGHT]),
      id: sf.id,
      images: sf[LOAD_POST.IMAGES],
      length: parseInt(sf[LOAD_POST.DIMENSIONS][LOAD_POST.LENGTH]),
      quantity: parseInt(sf[LOAD_POST.DIMENSIONS][LOAD_POST.QUANTITY]),
      weight: parseInt(sf[LOAD_POST.DIMENSIONS][LOAD_POST.WEIGHT]),
      width: parseInt(sf[LOAD_POST.DIMENSIONS][LOAD_POST.WIDTH]),
      loadArrangement: sf[LOAD_POST.LOAD_ARRANGMENT],
      miscellaneousNotes: sf[LOAD_POST.MISCELLANEOUS_NOTES],
    };
  });
};

const handleDateFormat = (date, time) => {
  const formatedDate =
    moment(date).format("YYYY-MM-DD") + "T" + time + ":00.000Z";
  return formatedDate;
};

export const getMinDate = (data, pickerType, type) => {
  if (pickerType === LOAD_POST.TO) {
    return moment(data[type].dateFrom).add(1, "d").toDate();
  }
  if (type === LOAD_POST.DROP_OFF) {
    return moment(data[LOAD_POST.PICK_UP].dateFrom).toDate();
  }
  return moment().toDate();
};

export const getDateRangeTitle = (isRange, pickerType, typeText) => {
  pickerType = capitalizeFirstLetter(pickerType);
  if (isRange) {
    return `${pickerType} date ${typeText} *`;
  }
  return `${pickerType} date *`;
};

export const getMinTime = (data, pickerType, timeType) => {
  if (timeType === "time") {
    const isCurrentDate = moment(
      data[pickerType][LOAD_POST.DATE_RANGE]
        ? data[pickerType][LOAD_POST.DATE_TO]
        : data[pickerType][LOAD_POST.DATE_FROM]
    ).isSame(new Date(), "day");

    if (isCurrentDate && pickerType === LOAD_POST.DROP_OFF) {
      return moment()
        .set({
          hour: moment(
            data[LOAD_POST.PICK_UP][LOAD_POST.TIME],
            "HH:mm"
          ).hours(),
          minute: moment(
            data[LOAD_POST.PICK_UP][LOAD_POST.TIME],
            "HH:mm"
          ).minutes(),
        })
        .add(5, "hours")
        .format("HH:mm");
    } else if (isCurrentDate) {
      return moment().format("HH:mm");
    }
  } else if (timeType === "timeTo") {
    let timee = data[pickerType]?.time?.replace(":", "");
    let timeToo = data[pickerType]?.timeTo?.replace(":", "");

    if (timee < timeToo) {
      return null;
    } else {
      return data[pickerType].timeTo;
    }
  }

  return null;
};

export const getTimeTitle = (pickerType, time, type) => {
  pickerType = capitalizeFirstLetter(pickerType);
  return `${pickerType} ${time} ${type}*`;
};

export const getDatePlusOne = (date, days = 1) => {
  return moment(date).add(days, "d").toDate();
};

export const getAddHourInDate = (value, hour) => {
  return moment()
    .set({
      hour: moment(value, "HH:mm").hours(),
      minute: moment(value, "HH:mm").minutes(),
    })
    .add(hour, "hours")
    .format("HH:mm");
};

/******************** 
@purpose : Used to set floating value
@Parameter : { number }
@Author : INIC
******************/

export const floatingValue = (number) => {
  let value = number;
  if (value.includes(".")) {
    let before_decimal = value.split(".")[0];
    let after_decimal = value.split(".")[1];
    if (before_decimal.length > 5 || after_decimal.length > 3) {
      return;
    }
  } else {
    if (number.length > 5) return;
  }
  return value;
};

export const checkDimension = async (file) => {
  var fileUpload = file;
  var reader = new FileReader();
  let isValid = true;
  reader?.readAsDataURL(fileUpload);
  return new Promise((resolve, reject) => {
    reader.onload = function (e) {
      var image = document.createElement("img" || "file");
      image.src = e.target.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;
        if (width > 4500 || height > 4000) {
          isValid = false;
          resolve(false);
        }
        isValid = true;
        resolve(true);
      };
    };
  }).then((data) => {
    return data;
  });
};

export const CommonDivRenderCard = (label, value1, value2) => {
  return (
    <div className="col-md-6">
      <div className="form-group d-flex align-items-center mb-md-4 mb-3">
        <label className="common-label">{label}</label>
        <div className="text-readonly">
          <span>
            {value1}
            {commonTernary(value2, `, ${value2}`, "")}
          </span>
        </div>
      </div>
    </div>
  );
};

export const CommonTdBuildDetail = (value) => {
  return <td className="text-capitalize">{value}</td>;
};

export const getMonthData = (param1, param2) => {
  const month = GRAPH_MONTHS.map((month) => [
    month,
    param1[month] ? param1[month] : 0,
  ]);
  month.unshift(param2);
  return month;
};

export const getDaysData = (param1, param2) => {
  let numberArray = [];

  for (let i = 1; i <= 31; i++) {
    numberArray.push([i.toString(), param1[`day${i}`] ? param1[`day${i}`] : 0]);
  }
  numberArray.unshift(param2);
  return numberArray;
};

export const getWeekData = (param1, param2) => {
  const week = GRAPH_WEEKLY.map((week) => [
    week,
    param1[week] ? param1[week] : 0,
  ]);
  week.unshift(param2);
  return week;
};

export const getLoadGraphMonthData = (param1, param2, param3, param4) => {
  const month = GRAPH_MONTHS.map((month) => [
    month,
    param1?.[month] ? param1?.[month] : 0,
    param3?.[month] ? param3?.[month] : 0,
    param4?.[month] ? param4?.[month] : 0,
  ]);
  month.unshift(param2);
  return month;
};
export const getLoadGraphDaysData = (param1, param2, param3, param4) => {
  let numberArray = [];

  for (let i = 1; i <= 31; i++) {
    numberArray.push([
      i.toString(),
      param1?.[`day${i}`] ? param1?.[`day${i}`] : 0,
      param3?.[`day${i}`] ? param3?.[`day${i}`] : 0,

      param4?.[`day${i}`] ? param4?.[`day${i}`] : 0,
    ]);
  }
  numberArray.unshift(param2);
  return numberArray;
};

export const loadingChart = () => {
  return <h5 className="text-center">Loading Chart...</h5>;
};

export const globalDateFormater = (dateFormatGlobal) => {
  window.addEventListener("storage", () => {});

  if (dateFormatGlobal) {
    return dateFormatGlobal;
  }
  return "MM/DD/YYYY";
};

export const textTrimStart = (values) => {
  if (values) {
    return values.trimStart();
  }
  return values;
};

export const progressBarScale = (value, total) => {
  return (value * 100) / total;
};

export const progressBarStatus = (value, max, min) => {
  return value > max ? "success" : value >= min ? "warning" : "danger";
};

/******************** 
@purpose : Used for disabledOptions to change load status
@Parameter : { ele, isExpired, loadStatus }
@Author : INIC
******************/
export const disabledOptions = (ele, isExpired, loadStatus) => {
  if (isExpired) {
    return ele === "posted" || ele === loadStatus;
  } else {
    return ele === loadStatus;
  }
};
/*******************
  @Purpose : Used to multipleLocation listing
  @Parameter : loadData
  @Author : INIC
  ******************/
export const multipleLocationList = (loadData) => {
  return typeof loadData === "object"
    ? loadData &&
        loadData?.map((location, index) => {
          return (
            <p className="text-dark mb-1" key={index}>{`${index + 1}: ${
              location?.name
            }`}</p>
          );
        })
    : loadData;
};

/*******************
  @Purpose : Used to set new order values
  @Parameter : list, startIndex, endIndex
  @Author : INIC
  ******************/
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

/** ****************** 
@purpose : Used for onKeyDown event
@Parameter : { evt }
@Author : INIC
***************** */
export const onKeyDownHandler = (evt) =>
  ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault();
