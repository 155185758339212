import * as types from "../Actions/types";

/******************* 
@purpose : Intital user  count data
@Author : INIC
******************/

const initialState = {
  session: {},
  dialogList: {},
  activeDialog: null,
  userInfo: {},
  chatHistory: {},
  chatCreds: {},
  quickblox_info: {},
  createdList: [],
};

const chatInfo = (session_ini_data = initialState, action = {}) => {
  switch (action.type) {
    case types.SAVE_USER_SESSION:
      return {
        ...session_ini_data,
        session: action.payload,
      };

    case types.ACTIVE_DIALOG:
      return {
        ...session_ini_data,
        activeDialog: action.payload,
      };

    case types.CHAT_USER_INFO:
      return {
        ...session_ini_data,
        userInfo: action.payload,
      };

    case types.DIALOG_LIST:
      return {
        ...session_ini_data,
        dialogList: action.payload,
      };

    case types.CHAT_HISTORY:
      return {
        ...session_ini_data,
        chatHistory: action.payload,
      };

    case types.CHAT_CREDS:
      return {
        ...session_ini_data,
        chatCreds: action.payload,
      };

    case types.QUICKBLOX_INFO:
      return {
        ...session_ini_data,
        quickblox_info: action.payload,
      };

    case types.CREATED_DIALOG:
      return {
        ...session_ini_data,
        createdList: action.payload,
      };

    case types.RESET_CHAT:
      return initialState;

    default:
      return session_ini_data;
  }
};

export default chatInfo;
