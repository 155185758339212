/******************* 
@Purpose : Used for portugal langauge default text
@Author : INIC
******************/

export const po = {
  CustomLabels: {
    dashboard: "painel de controle",
    users: "Comercial",
    content: "conteúdo",
    emailTemplateslabel: "Notificação de Email",
    master: "mestre",
    roles: "Transcação",
    Transcation: "Transcação",
    media: "meios de comunicação",
    support: " Apoio, suporte",
    settings: "definições",
    smtp: "SMTP",
    payment: "Detalhes do SDK do gateway de pagamento",
    blogs: "blogs",
    adminuser: "Usuários admin",
    staticpages: "Statische Seiten",
    faq: "Perguntas frequentes",
    generalsettings: "Configurações Gerais",
    SocialSdk: "Detalhes do SDK de mídia social",
    socilamedia: "Mídia social",
    SocialLinks: "Links de mídia social",
    paymentGateway: "Detalhes SDK do gateway de pagamento",
    emailNotification: "Configurações de notificação por email",
  },
  MasterMangement: {
    master: "Mistrz",
    manageMaster: "Zarządzaj Master",
    addCountry: "Dodaj kraj",
    countryName: "Nazwa kraju",
    countryCode: "Kod pocztowy",
    phoneCode: "Kod telefonu",
    add: "Dodaj",
    addCountryTimeZone: "Dodaj strefę czasową kraju",
    timeZone: "Strefa czasowa",
    addCurrency: "Dodaj walutę kraju",
    currency: "Waluta",
    status: "Status",
    action: "Akcja",
    noRecord: "Nic nie znaleziono!",
  },
  GeneralSettings: {
    general: "General Settings",
    settings: "Settings",
    siteName: "Site Name",
    siteFavicon: "Site Favicon",
    siteSmallLogo: "Site Logo (Small)",
    siteBigLarge: "Site Logo (Large)",
    siteMaintainance: "Site Under Maintainance",
    siteMaintainanceMessage:
      "Set the website status to offline. Display the site offline messages.",
    offlineMessage: "Offline message",
    metaData: "Meta Data",
    metaTitle: "Meta Title",
    maxCharacterTitle: "Maximum 70 characters is suitable",
    metaDescription: "Meta Description",
    maxCharacterDescription: "Maximum 160 characters is suitable",
    metaKeywords: "Meta Keywords",
    maxCharacterKeywords: "Maximum 150 characters is suitable",
    analyticalData: "Analytical Data",
    headerSnippet: "Header Snippet",
    footerSnippet: "Footer Snippet",
    timezone: "Timezone",
    dateformat: "Date Format",
    currency: "Currency",
    timeFormat: "Time Format",
    startDate: "Start Date",
    endDate: "End Date ",
  },
  EmailList: {
    emailTemplates: "Email Templates",
    addNewTemplate: "Add New Template",
    templateTitle: "Template Title",
    ID: "ID",
    subject: "Subject",
    createdBy: "Created By",
    dateTime: "Date Time",
    action: "Action",
    noRecord: "No Records Found!",
  },
  EmailTemplates: {
    emailTemplates: "Email Templates",
    addNewTemplate: "Add New Template",
    templateTitle: "Template Title",
    title: "Title",
    subject: "Subject",
    fromemailAddress: "From email Address",
    tags: "Tags",
  },
  ContentManagement: {
    CMS: {
      edit: "Editar Página",
      add: "Adicionar Página",
      content: "conteúdo",
      category: "Categoria",
      title: "Título",
      slug: "lesma",
      metaKeywords: "Meta Palavras-chave",
      metaDescription: "Meta Descrição",
      blogDescription: "Descrição do blog",
      image: "Imagem",
      caption: "rubrica",
      postDate: "Data de postagem",
      tag: "Tag",
      status: "Status",
      author: "autor",
    },
    CMSListing: {
      blog: "Blogs",
      addNewPage: "Adicionar nova página",
      articleName: "Nome do artigo",
      category: "Categoria",
      created: "Criada",
      status: "Status",
      action: "Açao",
      noRecord: "Nenhum registro foi encontrado!",
      search: "Pesquisa",
    },
    FAQ: {
      content: "conteúdo",
      faq: "FAQ",
      faqList: "Lista de FAQ",
      faqCategory: "FAQ Kategorie",
      addCategory: "Categoria FAQ",
      addNewFAQ: "Adicionar novo FAQ",
      category: "Categoria",
      question: "Questão",
      answer: "Responda",
      action: "Açao",
      search: "Pesquisa",
    },
  },
  Usermanagement: {
    edituser: {
      labels: {
        userprofile: "Perfil de usuário",
        firsname: "Primeiro nome",
        lastname: "Último nome",
        email: "O email",
        company: "Companhia",
        status: "Status",
        phoneNumber: "Número de telefone",
        birthdate: "Data de nascimento",
        website: "Local na rede Internet",
        gender: "Gênero",
        address: "Endereço",
        country: "Country",
      },
      buttons: {
        save: "Salve",
        saving: "Salvando",
        delete: "Delete",
      },
    },
  },
};
